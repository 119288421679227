import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class VendasService {
	constructor(private apiService: ApiService) {}

	get(id: string = null): Observable<any> {
		const url = `/venda/${id}`
		return this.apiService.get(url)
	}

	getExcluida(id: string = null): Observable<any> {
		const url = `/venda-excluida/${id}`
		return this.apiService.get(url)
	}

	getAll(filtro): Observable<any> {
		const url = `/venda`
		return this.apiService.get(url, filtro)
	}

	getAllRecibosEmitidos(filtro): Observable<any> {
		const url = `/venda-recibi-emitido`
		return this.apiService.get(url, filtro)
	}

	getAllTratamentosVendidos(filtro): Observable<any> {
		const url = `/relatorio-tratamentos-vendidos`
		return this.apiService.post(url, filtro)
	}

	getRelatorio(filtro): Observable<any> {
		return this.apiService.post('/relatorio-vendas', filtro)
	}

	getRelatorioLiquido(filtro): Observable<any> {
		return this.apiService.post('/relatorio-vendas-liquida', filtro)
	}

	criarVendaDireta(form): Observable<any> {
		return this.apiService.post('/vendas', form)
	}

	delete(id: string): Observable<any> {
		const url = `/venda/${id}`
		return this.apiService.delete(url)
	}

	getPendentes(filtro?): Observable<any> {
		return this.apiService.get('/vendas-pendentes', filtro)
	}

	getAtendimentosReconversao(filtro?): Observable<any> {
		return this.apiService.get('/atendimentos-reconversao', filtro)
	}

	getAgendamentoVenda(agendaId): Observable<any> {
		return this.apiService.get(`/venda-converter-venda/${agendaId}`)
	}

	converterAtendimentoEmVenda(form): Observable<any> {
		return this.apiService.post(`/converter-atendimento-venda`, form)
	}

	converterOrcamentoEmVenda(id: string, form): Observable<any> {
		return this.apiService.post(`/converter-orcamento-venda/${id}`, form)
	}

	verificarParcelasPagas(id: string): Observable<any> {
		return this.apiService.get(`/verificar-parcelas-pagas/${id}`)
	}

	converterParcelasPagasEmCreditoCarteiraDigital(id: number): Observable<any> {
		return this.apiService.post(`/converter-parcelas-pagas-em-credito/${id}`)
	}

	gerarDevolucaoParcelasPagas(id: number): Observable<any> {
		return this.apiService.post(`/gerar-devolucao-parcelas-pagas/${id}`)
	}

	putRecibo(id: number): Observable<any> {
		return this.apiService.post(`/venda-recibo/${id}`)
	}

	reconverterAtendimentoEmVenda(form): Observable<any> {
		return this.apiService.post(`/reconverter-atendimento-venda`, form)
	}

	getVendasPorFormaRecebimento(form): Observable<any> {
		return this.apiService.post('/relatorio-vendas-forma-recebimento', form)
	}

	buscarDadosExclusao(id: string): Observable<any> {
		return this.apiService.get(`/dados-exclusao-venda/${id}`)
	}


	rankingPacientes( form?: any): Observable<any> {
		return this.apiService.get('/ranking-pacientes', form)
	}

	pdfRanking(form): Observable<any> {
		return this.apiService.post('/ranking-pdf', form)
	}

	
}
