import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class ContasBancariasService {
	constructor(
		private apiService: ApiService
	) {}
		
	get(id): Observable<any> {
		let url : string = `/contas-bancarias/${id}`
		return this.apiService.get(url)
	}
		
	getAll(dadosFiltro?): Observable<any> {
		let url : string = `/contas-bancarias`
		return this.apiService.get(url, dadosFiltro)
	}

	getAllMovimentos(dadosFiltro?): Observable<any> {
		let url : string = `/contas-bancarias-movimentos`
		return this.apiService.get(url, dadosFiltro)
	}
		
	restore(id) : Observable<any> {
		return this.apiService.get('/contas-bancarias/reativar/' + id)
	}
	
	post(form) : Observable<any> {
		return this.apiService.post('/contas-bancarias', form)
	}
	
	put(id: string, form) : Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/contas-bancarias/' + id, form)
	}
	
	delete(id) {
		return this.apiService.delete('/contas-bancarias/' + id)
	}

	postMovimento(form) : Observable<any> {
		return this.apiService.post('/conta-bancaria/movimento', form)
	}

	deleteMovimento(id) : Observable<any> {
		return this.apiService.delete('/contas-bancarias/movimento/' + id)
	}
}