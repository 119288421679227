import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class ClientesService {
	constructor(private apiService: ApiService) {}

	getAll(form?): Observable<any> {
		const url = `/clientes`
		return this.apiService.get(url, form)
	}

	getAllFilter(form?): Observable<any> {
		const url = `/clientes-filter`
		return this.apiService.get(url, form)
	}

	getAllSelect(form?): Observable<any> {
		const url = `/clientes-select`
		return this.apiService.get(url, form)
	}

	getSemAtendimentos(form?): Observable<any> {
		const url = `/clientes/sem-atendimentos`
		return this.apiService.get(url, form)
	}

	pdfRecorrencia(form?): Observable<any> {
		const url = `/clientes/sem-atendimentos/pdf`
		return this.apiService.get(url, form)
	}

	getAniversario(form?): Observable<any> {
		const url = `/clientes/aniversario`
		return this.apiService.get(url, form)
	}

	get(id): Observable<any> {
		const url = `/clientes/${id}`
		return this.apiService.get(url)
	}

	getCreditosCliente(id): Observable<any> {
		const url = `/creditos-cliente/${id}`
		return this.apiService.get(url)
	}

	getHistoricoAtendimentos(id: number, filtro): Observable<any> {
		const url = `/historico-atendimentos-cliente/${id}`
		return this.apiService.get(url, filtro)
	}

	getHistoricoNaoComparecimento(id: number, filtro): Observable<any> {
		const url = `/historico-nao-comparecimento-cliente/${id}`
		return this.apiService.get(url, filtro)
	}

	getCompras(id: number, filtro): Observable<any> {
		const url = `/compras-cliente/${id}`
		return this.apiService.get(url, filtro)
	}

	getPorNome(filtro): Observable<any> {
		const url = `/cliente/nome`
		return this.apiService.get(url, filtro)
	}

	getCarteiraDigital(id: number): Observable<any> {
		const url = `/carteira-digital-cliente/${id}`
		return this.apiService.get(url)
	}

	post(form): Observable<any> {
		return this.apiService.post('/clientes', form)
	}

	put(id: string, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/clientes/' + id, form)
	}

	restore(id: string): Observable<any> {
		return this.apiService.get('/clientes/reativar/' + id)
	}

	delete(id) {
		return this.apiService.delete('/clientes/' + id)
	}

	uploadImagem(id, request): Observable<any> {
		return this.apiService.post('/clientes/upload/' + id, request)
	}

	uploadFoto(id, request): Observable<any> {
		return this.apiService.post('/clientes/upload-foto/' + id, request)
	}

	deleteFoto(id): Observable<any> {
		const url = `/clientes/delete-foto/${id}`
		return this.apiService.get(url)
	}

	renovarDocumentosTratamentos(form): Observable<any> {
		return this.apiService.post('/clientes-renovar-documento-tratamento', form)
	}

	getHistoricoSistemaAntigo(id): Observable<any> {
		const url = `/clientes/historicoAtendimentosSistemaAntigo/${id}`
		return this.apiService.get(url)
	}
}
