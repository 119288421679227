import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { retry, catchError, shareReplay } from 'rxjs/operators'
import { Utils } from '../../../assets/ts/utils'

@Injectable()
export class CategoriasTratamentosService {
    constructor(
        private apiService: ApiService
    ) {}
    
    get(id): Observable<any> {
        let url : string = `/categorias-tratamentos/${id}`
        return this.apiService.get(url).pipe(
            retry(5),
            catchError(() => {
                return EMPTY
            }),
            shareReplay()
        )
    }
    
    getAll(filtro?): Observable<any> {
        let url : string = `/categorias-tratamentos`
        return this.apiService.get(url, filtro).pipe(
            retry(5),
            catchError(() => {
                return EMPTY
            }),
            shareReplay()
        )
    }
    
    restore(id) : Observable<any> {
        return this.apiService.get('/categorias-tratamentos/reativar/' + id).pipe(
            retry(5),
            catchError(() => {
                return EMPTY
            }),
            shareReplay()
        )
    }
    
    post(form) : Observable<any> {
        return this.apiService.post('/categorias-tratamentos', form)
    }
    
    put(id: string, form) : Observable<any> {
        form._method = 'PUT'
        return this.apiService.post('/categorias-tratamentos/' + id, form)
    }
    
    delete(id: number) {
        return this.apiService.delete('/categorias-tratamentos/' + id)
    }
}