import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class BancosService {
    constructor(
        private apiService: ApiService
    ) {}
    
    get(id): Observable<any> {
        let url : string = `/bancos/${id}`
        return this.apiService.get(url)
    }
    
    getAll(): Observable<any> {
        let url : string = `/bancos`
        return this.apiService.get(url)
    }
        
}