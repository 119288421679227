import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'


@Injectable()
export class DestinoCompensacaoService {

    constructor(
        private apiService: ApiService
    ) { }

        
    get(id): Observable<any> {
		let url : string = `/destinos-compensacao/${id}`
		return this.apiService.get(url)
    }
    
    getAll(filtro?): Observable<any> {
		let url : string = `/destinos-compensacao`
		return this.apiService.get(url, filtro)
    }
    
    restore(id: number) : Observable<any> {
        return this.apiService.get(`/destinos-compensacao/reativar/${id}`)
    }
    
    post(form) : Observable<any> {
        return this.apiService.post('/destinos-compensacao', form)
    }

    put(id: string, form) : Observable<any> {
        form._method = 'PUT'
        return this.apiService.post('/destinos-compensacao/' + id, form)
    }
    
    delete(id) {
        return this.apiService.delete('/destinos-compensacao/' + id)
    }
}


