import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

interface Response {
	resposta: string
	data?: any
	success: 0 | 1
}

@Injectable()
export class ClinicaService {
	constructor(private apiService: ApiService) {}

	post(form): Observable<any> {
		return this.apiService.post('/dados-clinica', form)
	}

	get(): Observable<any> {
		const url = `/dados-clinica`
		return this.apiService.get(url)
	}

	put(form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/dados-clinica', form)
	}

	uploadClinicaImagem(id: string, request): Observable<any> {
		return this.apiService.post('/dados-clinica/upload/' + id, request)
	}

	deleteFoto(id: string): Observable<Response> {
		const url = `/dados-clinica/deletar-imagem/${id}`
		return this.apiService.get(url)
	}
}
