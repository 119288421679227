import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { apiConfig } from '../../../environments/apiConfig'
import { Utils } from '../../../assets/ts/utils'

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup
	loading = false

	constructor(
		private fb: FormBuilder,
		private httpClient: HttpClient,
		private router: Router
	) {}

	ngOnInit() {
		this.loginForm = this.fb.group({
			usuario: this.fb.control('', [
				Validators.required,
				Validators.email
			]),
			senha: this.fb.control('', [Validators.required])
		})
	}

	onSubmit() {
		this.loading = true
		this.httpClient
			.post(apiConfig.apiUrl + '/auth/login', this.loginForm.value)
			.subscribe(
				(response) => {
					if (response['access_token']) {
						localStorage.removeItem('access_token')
						localStorage.removeItem('usuario')

						localStorage.setItem(
							'access_token',
							response['access_token']
						)

						const headers = new HttpHeaders({
							'Content-Type': 'application/json',
							Authorization: `Bearer ${response['access_token']}`
						})

						this.httpClient
							.post(
								apiConfig.apiUrl + '/auth/me',
								{},
								{
									headers: headers
								}
							)
							.subscribe((response2) => {
								localStorage.setItem(
									'usuario',
									JSON.stringify(response2)
								)
								this.router.navigate(['agenda'])
							})
					}
				},
				(error) => {
					this.loading = false
					Utils.showNotification('warning', error.error.error)
				}
			)
	}
}
