import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class FormaRecebimentoService {
	constructor(private apiService: ApiService) {}

	get(id: string): Observable<any> {
		const url = `/formas-recebimento/${id}`
		return this.apiService.get(url)
	}

	getAll(dadosFiltro?): Observable<any> {
		const url = `/formas-recebimento`
		return this.apiService.get(url, dadosFiltro)
	}

	getACombinar(): Observable<any> {
		const url = `/verifica-formas-recebimento-a-combinar`
		return this.apiService.get(url)
	}

	changeACombinar(aCombinar: boolean): Observable<any> {
		const url: string = aCombinar
			? `/ativa-formas-recebimento-a-combinar`
			: `/desativa-formas-recebimento-a-combinar`
		return this.apiService.get(url)
	}

	post(form): Observable<any> {
		return this.apiService.post('/formas-recebimento', form)
	}

	put(id: string, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/formas-recebimento/' + id, form)
	}

	delete(id: string) {
		return this.apiService.delete('/formas-recebimento/' + id)
	}

	restore(id: string): Observable<any> {
		return this.apiService.get(`/formas-recebimento/reativar/` + id)
	}

	uploadImage(id: string, request): Observable<any> {
		return this.apiService.post('/formas-recebimento/upload/' + id, request)
	}
}
