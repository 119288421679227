import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()
export class CategoriasReceitasService {
    constructor(
        private apiService: ApiService
    ) {}
    
    get(id): Observable<any> {
        let url : string = `/categorias-receitas/${id}`
        return this.apiService.get(url)
    }
    
    getAll(filtro?): Observable<any> {
        let url : string = `/categorias-receitas`
        return this.apiService.get(url, filtro)
    }
    
    restore(id) : Observable<any> {
        return this.apiService.get('/categorias-receitas/reativar/' + id)
    }
    
    post(form) : Observable<any> {
        return this.apiService.post('/categorias-receitas', form)
    }
    
    put(id: string, form) : Observable<any> {
        form._method = 'PUT'
        return this.apiService.post('/categorias-receitas/' + id, form)
    }
    
    delete(id) {
        return this.apiService.delete('/categorias-receitas/' + id)
    }
}