import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class AgendaService {
	constructor(private apiService: ApiService) {}

	get(id: string): Observable<any> {
		const url = `/agenda/${id}`
		return this.apiService.get(url)
	}

	getForAtendimento(id: string): Observable<any> {
		const url = `/agenda-atendimento/${id}`
		return this.apiService.get(url)
	}

	getByDay(form): Observable<any> {
		const url = `/agenda-data`
		return this.apiService.get(url, form)
	}

	getByPeriodo(form): Observable<any> {
		const url = `/agenda-periodo`
		return this.apiService.get(url, form)
	}

	getByProfissional(form): Observable<any> {
		const url = `/agenda-data-profissional`
		return this.apiService.get(url, form)
	}

	getBySala(form): Observable<any> {
		const url = `/agenda-data-sala`
		return this.apiService.get(url, form)
	}

	getByPaciente(form): Observable<any> {
		const url = `/agenda-data-paciente`
		return this.apiService.get(url, form)
	}

	getAtendimentosPendentes(filtro?): Observable<any> {
		const url = `/atendimentos-pendentes`
		return this.apiService.get(url, filtro)
	}

	getAtendimentosPendentesNotificacao(filtro?): Observable<any> {
		const url = `/atendimentos-pendentes-notificacao`
		return this.apiService.get(url, filtro)
	}

	getAtendimentoAnexos(id): Observable<any> {
		return this.apiService.get(`/atendimento-anexos/${id}`)
	}

	getAgendaImpressao(filtro?): Observable<any> {
		const url = `/agenda-impressao`
		return this.apiService.get(url, filtro)
	}

	getListaEspera(form): Observable<any> {
		const url = `/agenda-lista-espera`
		return this.apiService.get(url, form)
	}

	verificaTurnoProfissional(form) {
		const url = `/agenda-turno-profissional`
		return this.apiService.post(url, form)
	}

	verificaTurnoAuxiliares(form) {
		const url = `/agenda-turno-auxiliares`
		return this.apiService.post(url, form)
	}

	verificaExtraProfissional(form) {
		const url = `/agenda-verifica-extra-profissional`
		return this.apiService.post(url, form)
	}

	verificaExtraAuxiliares(form) {
		const url = `/agenda-verifica-extra-auxiliares`
		return this.apiService.post(url, form)
	}

	post(form): Observable<any> {
		const url = '/agenda'
		return this.apiService.post(url, form)
	}

	postRetroativo(form): Observable<any> {
		const url = '/agendamento-retroativo'
		return this.apiService.post(url, form)
	}

	postNovoCliente(form): Observable<any> {
		const url = '/agenda/novo/paciente'
		return this.apiService.post(url, form)
	}

	put(id: string, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post(`/agenda/${id}`, form)
	}

	delete(id: string): Observable<any> {
		const url = `/agenda/${id}`
		return this.apiService.delete(url)
	}

	sendEmailSmsAgenda(id: string): Observable<any> {
		const url = `/email-sms-agenda/${id}`
		return this.apiService.get(url)
	}
}
