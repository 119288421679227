import { Injectable } from '@angular/core'
import { apiConfig } from '../../../environments/apiConfig'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable, EMPTY } from 'rxjs'
import { ErrorObservable } from 'rxjs/observable/ErrorObservable'
import { retry, catchError, shareReplay, map } from 'rxjs/operators'

import { Utils } from '../../../assets/ts/utils'
import { Router } from '@angular/router'

@Injectable()
export class ApiService {
	constructor(private http: HttpClient, private router: Router) {}

	private formatErrors(error: any) {
		return new ErrorObservable(error.error)
	}

	get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
		return this.http
			.get(`${apiConfig.apiUrl}${path}`, {
				headers: Utils.buscarTokenAutenticacao(),
				params
			})
			.pipe(
				map((data) => {
					return data
				})
			)
			.pipe(
				retry(5),
				catchError((error) => {
					if (error.status === 401) {
						Utils.logout(this.router)
						Utils.showNotification('danger', error.error.message)
					}
					return EMPTY
				}),
				shareReplay()
			)
	}

	put(path: string, body: Object = {}): Observable<any> {
		return this.http
			.put(`${apiConfig.apiUrl}${path}`, JSON.stringify(body))
			.pipe(catchError(this.formatErrors))
	}

	post(path: string, body: Object = {}): Observable<any> {
		return this.http
			.post(`${apiConfig.apiUrl}${path}`, body, {
				headers: Utils.buscarTokenAutenticacao()
			})
			.pipe(catchError(this.formatErrors))
	}

	delete(path): Observable<any> {
		return this.http
			.delete(`${apiConfig.apiUrl}${path}`, {
				headers: Utils.buscarTokenAutenticacao()
			})
			.pipe(catchError(this.formatErrors))
	}
}
