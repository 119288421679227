import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class PaisesService {
    constructor(private apiService: ApiService) {
        
    }
    
    getPaises(): Observable<any> {
        let url : string = `/paises`
        return this.apiService.get(url)
    }
    
    getPaisesByID(id: string){
        let url : string = `/paises/${id}`
        return this.apiService.get(url)
    }
    
}