import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class LogVendaService {
    constructor(
        private apiService: ApiService
    ) {}

    getAll(filtro) : Observable<any>{
		let url = `/logs-venda` 
        return this.apiService.get(url, filtro)
    }
}