import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class TratamentoPacoteService {
    constructor(
        private apiService: ApiService
    ) {}

    post(form) : Observable<any> {
        return this.apiService.post('/tratamento-pacote', form)
    }
    
    getAll( idTratamento? ): Observable<any> {
        let url : string = `/tratamento-pacote`
        return this.apiService.get(url, idTratamento)
    }

    get(id: string): Observable<any> {
        let url : string = `/tratamento-pacote/${id}`
        return this.apiService.get(url)
    }

    put(id: string, form) : Observable<any> {
        form._method = 'PUT'
        return this.apiService.post('/tratamento-pacote/'+id, form)
    }

    delete(id) {
        return this.apiService.delete('/tratamento-pacote/'+id)
    }

}