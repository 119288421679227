import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class ContatoService {
	constructor(private apiService: ApiService) {}

	get(id: number): Observable<any> {
		const url = `/contato/${id}`
		return this.apiService.get(url)
	}

	getAll(filtro?): Observable<any> {
		const url = `/contato`
		return this.apiService.get(url, filtro)
	}

	getAllFilter(filtro?): Observable<any> {
		const url = `/contato-filter`
		return this.apiService.get(url, filtro)
	}

	post(form): Observable<any> {
		const url = '/contato'
		return this.apiService.post(url, form)
	}

	put(id: string, form): Observable<any> {
		const url = `/contato/${id}`
		return this.apiService.post(url, form)
	}

	delete(id: number): Observable<any> {
		const url = `/contato/${id}`
		return this.apiService.delete(url)
	}

	reativar(id: number): Observable<any> {
		let url = `/contato/reativar/${id}`
		return this.apiService.post(url)
	}
}
