import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class MedidasService {
    constructor(private apiService: ApiService) {
        
    }
    
    getAll(): Observable<any> {
        let url : string = `/medidas`
        return this.apiService.get(url)
    }
    
    get(id){
        let url : string = `/medidas/${id}`
        return this.apiService.get(url)
    }
    
}