import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class AvisoProfissionalOrcamentoService {
	constructor(private apiService: ApiService) {}

	post(form): Observable<any> {
		return this.apiService.post('/orcamento-aviso', form)
	}
}
