import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class DestinoService {
    constructor(
        private apiService: ApiService
    ) {}
    
    get(id): Observable<any> {
		let url : string = `/destinos/${id}`
		return this.apiService.get(url)
    }
    
    getAll(filtro?): Observable<any> {
		let url : string = `/destinos`
		return this.apiService.get(url, filtro)
    }
    
    restore(id: number) : Observable<any> {
        return this.apiService.get(`/destinos/reativar/${id}`)
    }
    
    post(form) : Observable<any> {
        return this.apiService.post('/destinos', form)
    }
    
    put(id: string, form) : Observable<any> {
        form._method = 'PUT'
        return this.apiService.post('/destinos/' + id, form)
    }
    
    delete(id) {
        return this.apiService.delete('/destinos/' + id)
    }
}