import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class ProdutosService {
	constructor(private apiService: ApiService) {}

	get(id): Observable<any> {
		const url = `/produtos/${id}`
		return this.apiService.get(url)
	}

	getAll(filtro?): Observable<any> {
		const url = `/produtos`
		return this.apiService.get(url, filtro)
	}

	getAllFilter(filtro?): Observable<any> {
		const url = `/produtos-filter`
		return this.apiService.get(url, filtro)
	}

	getAllProdutosInsumosComCompras(filtro?): Observable<any> {
		const url = `/produtos-insumos-habilitados`
		return this.apiService.get(url, filtro)
	}

	getAllVendas(): Observable<any> {
		const url = `/produtos-venda`
		return this.apiService.get(url)
	}

	getAllInsumosVendas(): Observable<any> {
		const url = `/produtos-insumos-venda`
		return this.apiService.get(url)
	}

	restore(id, element): Observable<any> {
		return this.apiService.post('/produtos/reativar/' + id, element)
	}

	post(element): Observable<any> {
		return this.apiService.post('/produtos', element)
	}

	put(id: string, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/produtos/' + id, form)
	}

	delete(id) {
		return this.apiService.delete('/produtos/' + id)
	}
}
