import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class OrcamentoNotificacoesService {
	constructor(private apiService: ApiService) {}

	getAll(filtro?): Observable<any> {
		const url = `/oportunidade-notificacoes`
		return this.apiService.get(url, filtro)
	}
}
