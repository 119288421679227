import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()
export class CidadesService {
    constructor(private apiService: ApiService) {

    }

    get(id): Observable<any> {
        let url : string = `/cidades/${id}`
        return this.apiService.get(url)
    }

    getCidades(uf_estado): Observable<any> {
        let url : string = `/cidades-estado/${uf_estado}`
        return this.apiService.get(url)
    }

}