import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class DiasSemanaAgendaService {
    constructor(
        private apiService: ApiService
    ) {}
    
    get(): Observable<any> {
		let url : string = `/dias-semana-agenda`
		return this.apiService.get(url)
    }
}