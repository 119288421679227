import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class AtendimentoInsumosAdicionaisService {
    constructor(
        private apiService: ApiService
    ) {}
    
    getAll(filtro?): Observable<any> {
        let url : string = `/atendimento-insumos-adicionais`
        return this.apiService.get(url, filtro)
    }
    
    post(element) : Observable<any> {
        return this.apiService.post('/atendimento-insumos-adicionais', element)
    }
    
    delete(id: string) {
        return this.apiService.delete('/atendimento-insumos-adicionais/' + id)
    }
}