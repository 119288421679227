import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class TratamentoCustoInsumoService {
    constructor(
        private apiService: ApiService
    ) {}

    post(form) : Observable<any> {
        return this.apiService.post('/custo-insumo-tratamento', form)
    }
    
    getAll( idTratamento ): Observable<any> {
        let url : string = `/custo-insumo-tratamento`
        return this.apiService.get(url, idTratamento)
    }

    get(id: string): Observable<any> {
        let url : string = `/custo-insumo-tratamento/${id}`
        return this.apiService.get(url)
    }

    put(id: number, form) : Observable<any> {
        form._method = 'PUT'
        return this.apiService.post(`/custo-insumo-tratamento/${id}`, form)
    }

    delete(id:number) {
        return this.apiService.delete(`/custo-insumo-tratamento/${id}`)
    }

}