import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()
export class FormaPagamentoService {
    constructor(
    private apiService: ApiService
    ) {}
    
    get(id): Observable<any> {
        let url : string = `/formas-pagamento/${id}`
        return this.apiService.get(url)
    }
    
    getAll(dadosFiltro?): Observable<any> {
        let url : string = `/formas-pagamento`
        return this.apiService.get(url, dadosFiltro)
    }
    
    post(form) : Observable<any> {
        return this.apiService.post('/formas-pagamento', form)
    }
    
    put(id: string, form) : Observable<any> {
        form._method = 'PUT'
        return this.apiService.post('/formas-pagamento/' + id, form)
    }
    
    delete(id) {
        return this.apiService.delete('/formas-pagamento/' + id)
    }

    restore(id: string): Observable<any> {
        return this.apiService.get(`/formas-pagamento/reativar/` + id )
    }
    
    uploadImage(id, request) : Observable<any> {
        return this.apiService.post('/formas-pagamento/upload/'+id, request)
    }
}