import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { Routes, RouterModule } from '@angular/router'
import { LoginComponent } from './security/login/login.component'

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component'

const routes: Routes = [
	{
		//     path: '',
		//     redirectTo: 'dashboard',
		//     pathMatch: 'full',
		//   }, {
		path: '',
		component: LoginComponent,
		pathMatch: 'full'
		//   }, {
		//     path: 'cadastro-clinica',
		//     component: CadastroClinicaComponent,
		//     pathMatch: 'full',
	},
	{
		path: '',
		component: AdminLayoutComponent,
		children: [
			{
				path: '',
				loadChildren:
					'./layouts/admin-layout/admin-layout.module#AdminLayoutModule'
			}
		]
	}
]

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forRoot(routes, {
			useHash: true
		})
	],
	exports: []
})
export class AppRoutingModule {}
