import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class ProtocolosService {
	constructor(private apiService: ApiService) {}

	post(form): Observable<any> {
		return this.apiService.post('/protocolos', form)
	}

	get(id: string): Observable<any> {
		const url = `/protocolos/${id}`
		return this.apiService.get(url)
	}

	getAll(filtro?): Observable<any> {
		const url = `/protocolos`
		return this.apiService.get(url, filtro)
	}

	put(id: string, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/protocolos/' + id, form)
	}

	delete(id) {
		return this.apiService.delete('/protocolos/' + id)
	}

	restore(id): Observable<any> {
		return this.apiService.get('/protocolos/reativar/' + id)
	}

	getVendidos(form): Observable<any> {
		return this.apiService.get('/protocolos-vendidos', form)
	}

	pdfVendidos(form): Observable<any> {
		return this.apiService.post('/protocolos-vendidos-pdf', form)
	}
}
