import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class DocumentosService {
    constructor(
        private apiService: ApiService
    ) {}
    
    get(id): Observable<any> {
        let url : string = `/documentos/${id}`
        return this.apiService.get(url)
    }
    
    getAll(filtro?): Observable<any> {
        let url : string = `/documentos`
        return this.apiService.get(url, filtro)
    }

    getDocumentosTratamentos(form): Observable<any> {
        return this.apiService.post('/documentos-tratamentos', form)
    }
    
    restore(id, element) : Observable<any> {
        return this.apiService.post('/documentos/reativar/' + id, element)
    }
    
    post(form) : Observable<any> {
        return this.apiService.post('/documentos', form)
    }
    
    put(id: string, form) : Observable<any> {
        form._method = 'PUT'
        return this.apiService.post('/documentos/' + id, form)
    }
    
    delete(id) {
        return this.apiService.delete('/documentos/' + id)
    }
}