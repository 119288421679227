import { Component, OnInit } from '@angular/core'
// import * as jquery from 'jquery'
declare let $: JQueryStatic

// declare const $: any
declare interface RouteInfo {
	path: string
	title: string
	icon: string
	class: string
	children?: any
}
export const ROUTES: RouteInfo[] = [
	{ path: '/agenda', 
	title: 'Agenda', 
	icon: 'schedule', 
	class: 'dropdown',
	children: [
		{
			path: '/agenda',
			title: 'Agenda',
			icon: 'dashboard',
			class: ''
		},
		{
			path: '/lista-espera',
			title: 'Lista de Espera',
			icon: 'dashboard',
			class: ''
		},
	] },
	{
		path: '/cadastros',
		title: 'Cadastros',
		icon: 'add_box',
		class: 'dropdown',
		children: [
			{
				path: '/clientes',
				title: 'Clientes',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/listagem-contatos',
				title: 'Contatos',
				icon: 'connect_without_contact',
				class: ''
			},
			{
				path: '/listagem-funil-vendas',
				title: 'Funil de Vendas',
				icon: 'connect_without_contact',
				class: ''
			},
			{
				path: '/funcionarios',
				title: 'Profissionais',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/categorias-tratamentos',
				title: 'Categoria de Tratamento',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/tratamentos',
				title: 'Tratamentos',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/protocolos',
				title: 'Protocolos',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/categorias-receitas',
				title: 'Categoria de Receitas',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/receitas',
				title: 'Receitas',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/documentos',
				title: 'Documentos',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/configurar-anamnese',
				title: 'Configurar Anamnese',
				icon: 'dashboard',
				class: ''
			},
			// {
			// 	path: '/prontuarios',
			// 	title: 'Prontuários',
			// 	icon: 'dashboard',
			// 	class: ''
			// },
			{
				path: '/fornecedores',
				title: 'Fornecedores',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/produtos',
				title: 'Produtos',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/listagem-de-onde-cliente-veio',
				title: 'De Onde Cliente Veio',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/cadastro-clinica',
				title: 'Cadastro da Clínica',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/motivo-desistencia',
				title: 'Motivos desistência',
				icon: 'dashboard',
				class: ''
			}
		]
	},
	{
		path: '/financeiro',
		title: 'Financeiro',
		icon: 'credit_card',
		class: 'dropdown',
		children: [
			{
				path: '/formas-recebimento',
				title: 'Formas de Recebimento',
				class: ''
			},
			{
				path: '/formas-pagamento',
				title: 'Formas de Pagamento',
				class: ''
			},
			{
				path: '/categorias-financeiras',
				title: 'Categorias Financeiras',
				class: ''
			},
			{ path: '/destinos', title: 'Destinos p/ Terceiros', class: '' },
			{
				path: '/destinos-compensacao',
				title: 'Destinos Compensação',
				class: ''
			},
			{ path: '/contas-bancarias', title: 'Contas Bancárias', class: '' }
			// { path: '/termos', title: 'Termos', class: '' }
		]
	},
	{
		path: '/gerenciamento',
		title: 'Gerenciamento',
		icon: 'settings_applications',
		class: 'dropdown',
		children: [
			{
				path: '/tipo-funcionario',
				title: 'Tipos de Profissionais',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/convenios',
				title: 'Convênios',
				icon: 'dashboard',
				class: ''
			},
			{ path: '/salas', title: 'Salas', icon: 'dashboard', class: '' },
			{
				path: '/configuracoes-agenda',
				title: 'Config. Agenda',
				icon: 'settings',
				class: ''
			},
			{
				path: '/configuracoes-vendas',
				title: 'Config. Vendas',
				icon: 'settings',
				class: ''
			}
		]
	},
	{ path: '/compras', title: 'Compras', icon: 'money', class: '' },
	{
		path: '/vendas-pendentes',
		title: 'Vendas Pendentes',
		icon: 'monetization_on',
		class: ''
	},
	{ path: '/vendas', title: 'Vendas', icon: 'attach_money', class: '' },
	{
		path: '/caixa',
		title: 'Caixa',
		icon: 'account_balance_wallet',
		class: ''
	},
	{
		path: '/orcamentos',
		title: 'Orçamentos',
		icon: 'shopping_basket',
		class: ''
	},
	{
		path: '/relatorios',
		title: 'Relatórios',
		icon: 'assignment',
		class: 'dropdown',
		children: [
			{
				path: '/relatorios-vendas',
				title: 'Vendas',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorio-contas',
				title: 'Contas',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorios-vendas-diarias',
				title: 'Vendas Diárias + Fechamento de Caixa',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorio-recorrencia',
				title: 'Relatório de Recorrência',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorios-carteira',
				title: 'Carteira Digital',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorios-creditos',
				title: 'Créditos',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorios-procedimentos-atendidos',
				title: 'Procedimentos Atendidos',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorios-procedimentos-vendidos',
				title: 'Procedimentos Vendidos',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorio-vendas-categoria',
				title: 'Vendas por categoria',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorio-protocolos-vendidos',
				title: 'Protocolos Vendidos',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorios-destinos-cheques',
				title: 'Destinos Cheques',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorios-recibos',
				title: 'Recibos Emitidos',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorios-orcamento',
				title: 'Orçamentos',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorios-comissao',
				title: 'Comissão',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorio-oportunidade',
				title: 'Oportunidades',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorios-aniversariantes',
				title: 'Aniversariantes',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorios-logs',
				title: 'Logs do sistema',
				icon: 'dashboard',
				class: ''
			},
			{
				path: '/relatorio-ranking',
				title: 'Ranking',
				icon: 'dashboard',
				class: ''
			}
		]
	}
]

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
	menuItems: any[]

	// constructor() {}

	ngOnInit() {
		this.menuItems = ROUTES.filter((menuItem) => menuItem)
	}

	isMobileMenu() {
		if ($(window).width() > 991) {
			return false
		}
		return true
	}
}
