import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class CaixaService {
	constructor(private apiService: ApiService) {}

	get(id: number): Observable<any> {
		return this.apiService.get(`/caixa/${id}`)
	}

	delete(id: number): Observable<any> {
		const url = `/caixa/${id}`
		return this.apiService.delete(url)
	}

	getAll(filtros?): Observable<any> {
		return this.apiService.get(`/registros-caixa`, filtros)
	}

	getAllFilter(filtros?): Observable<any> {
		return this.apiService.get(`/caixa-filter`, filtros)
	}

	getAllChequesDestinados(filtros?): Observable<any> {
		return this.apiService.post(`/cheques-destinados`, filtros)
	}

	getContasPagar(filtros?) {
		return this.apiService.get(`/caixa-contas-pagar`, filtros)
	}

	getVendasReceber(filtros?) {
		return this.apiService.get(`/caixa-vendas-receber`, filtros)
	}

	RelatorioContas(filtros?) {
		return this.apiService.get(`/relatorio-contas`, filtros)
	}

	getRelatorioSaidas(filtros?) {
		return this.apiService.post(`/relatorio-contas-saidas`, filtros)
	}

	getRelatorioEntradas(filtros?) {
		return this.apiService.post(`/relatorio-contas-entradas`, filtros)
	}

	createSaidaParcelada(form): Observable<any> {
		return this.apiService.post('/adicionar-saida-parcelada', form)
	}

	createEntradaAvulsa(form): Observable<any> {
		return this.apiService.post('/adicionar-entrada-avulsa', form)
	}

	put(form): Observable<any> {
		return this.apiService.post('/caixa-update', form)
	}

	quitarPendencia(id: number, form): Observable<any> {
		return this.apiService.post(`/quitar-pendencia/${id}`, form)
	}

	postMovimentosPagosContasPagar(form): Observable<any> {
		return this.apiService.post(`/movimentos-pagos-contas-pagar`, form)
	}

	postMovimentosPagosContasReceber(form): Observable<any> {
		return this.apiService.post(`/movimentos-pagos-contas-receber`, form)
	}

	postDestinarCheque(form): Observable<any> {
		return this.apiService.post(`/destinar-cheque`, form)
	}
}
