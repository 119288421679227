import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'

@Injectable()
export class ConfiguracoesAgendaService {
    constructor(
        private apiService: ApiService
    ) {}
    
    get(): Observable<any> {
        let url : string = `/configuracoes-agenda`
        return this.apiService.get(url)
    }
    
    getConfigData(data: string): Observable<any> {
        let url : string = `/configuracoes-agenda-data`
        return this.apiService.get(url, new HttpParams().set('data', data))
    }
    
    verificaAgendamentos(form) : Observable<any> {
        return this.apiService.post('/configuracoes-agenda-verifica-agendamentos', form)
    }

    post(form) : Observable<any> {
        return this.apiService.post('/configuracoes-agenda', form)
    }
}