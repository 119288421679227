import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class AnamneseService {
	constructor(private apiService: ApiService) {}

	getAll(filtro?): Observable<any> {
		return this.apiService.get(`/anamnese`, filtro)
	}

	post(form): Observable<any> {
		return this.apiService.post('/anamnese', form)
	}

	postRespostas(form): Observable<any> {
		return this.apiService.post('/responder-anamnese', form)
	}

	postRespostasAtualiza(form): Observable<any> {
		return this.apiService.post('/atualizar-respostas-anamnese', form)
	}

	getRespostas(id): Observable<any> {
		const url = `/respostas-anamnese/${id}`
		return this.apiService.get(url)
	}

	deleteRespostas(id): Observable<any> {
		const url = `/delete-respostas-anamnese/${id}`
		return this.apiService.delete(url)
	}

	deletePergunta(id): Observable<any> {
		const url = `/anamnese/${id}`
		return this.apiService.delete(url)
	}

	deletePerguntaeRespostas(id): Observable<any> {
		const url = `/delete-pergunta-respostas-anamnese/${id}`
		return this.apiService.delete(url)
	}
}
