import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class AtendimentoExamesService {
    constructor(
        private apiService: ApiService
    ) {}

    post(id_agenda,id_cliente,id_atendimento_tratamento, form) : Observable<any> {
        return this.apiService.post(`/atendimento-exames?id_agenda=${id_agenda}&id_cliente=${id_cliente}&id_atendimento_tratamento=${id_atendimento_tratamento}`, form);
    }
    
    getAll( form ): Observable<any> {
        let url : string = `/atendimento-exames`
        return this.apiService.get(url, form)
    }

    deleteAnexos(id) {
        return this.apiService.delete('/atendimento-exames/'+id)
    }


    
    
    
    postExameCliente(id_cliente, form) : Observable<any> {
        return this.apiService.post(`/atendimento-exames-cliente?id_cliente=${id_cliente}`, form);
    }
    
    getAllExamesCliente( form ): Observable<any> {
        let url : string = `/atendimento-exames-cliente`
        return this.apiService.get(url, form)
    }

    deleteAnexosExamesCliente(id) {
        return this.apiService.delete('/atendimento-exames-cliente/' + id)
    }

}