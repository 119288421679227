import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class SalasService {
    constructor(
        private apiService: ApiService
    ) {}
        
    get(id): Observable<any> {
        let url : string = `/salas/${id}`
        return this.apiService.get(url)
    }
    
    getAll(dadosFiltro?): Observable<any> {
        let url : string = `/salas`
        return this.apiService.get(url, dadosFiltro)
    }

    getAgendamentos(id: number): Observable<any> {
        return this.apiService.get(`/agendamentos-sala/${id}`)
    }
    
    restore(id) : Observable<any> {
        return this.apiService.get('/salas/reativar/'+id)
    }
    
    post(form) : Observable<any> {
        return this.apiService.post('/salas', form)
    }
    
    put(id: string, form) : Observable<any> {
        form._method='PUT'
        return this.apiService.post('/salas/'+id, form)
    }
    
    delete(id) {
        return this.apiService.delete('/salas/'+id)
    }
}