import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class AtendimentoObservacoesService {
    constructor(
        private apiService: ApiService
    ) {}

    post(form) : Observable<any> {
        return this.apiService.post(`/atendimento-observacoes`, form)
    }
    
    getAll( form ): Observable<any> {
        let url : string = `/atendimento-observacoes`
        return this.apiService.get(url, form)
    }

}