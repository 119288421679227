import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class ComprasService {
	constructor(private apiService: ApiService) {}

	get(id: string = null): Observable<any> {
		const url = `/compras/${id}`
		return this.apiService.get(url)
	}

	getAll(filtro): Observable<any> {
		const url = `/compras`
		return this.apiService.get(url, filtro)
	}

	insereXml(form): Observable<any> {
		return this.apiService.post('/compras/xml', form)
	}

	post(form): Observable<any> {
		return this.apiService.post('/compras', form)
	}

	put(id: string, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post(`/compras/${id}`, form)
	}

	delete(id: string): Observable<any> {
		const url = `/compras/${id}`
		return this.apiService.delete(url)
	}
}
