import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'
import { Utils } from '../../../assets/ts/utils'

@Injectable()
export class CreditosService {
    constructor(private apiService: ApiService) {

    }

    converterCreditoTratamentoEmDinheiro(data): Observable<any> {
        return this.apiService.post(`/creditos-tratamentos/converter-credito`, data)
    }

    getAll(form?): Observable<any> {
		const url = `/creditos-relatorio`
		return this.apiService.get(url, form)
	}

	getAllCarteira(form?): Observable<any> {
		const url = `/creditos-dinheiro-relatorio`
		return this.apiService.get(url, form)
	}

	getAllCarteiraPdf(form): Observable<any> {
		return this.apiService.post('/creditos-pdf-dinheiro', form)
	}

	pdfCreditos(form): Observable<any> {
		return this.apiService.post('/creditos-pdf', form)
	}

}