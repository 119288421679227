import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class OportunidadesService {
	constructor(private apiService: ApiService) {}

	getAll(filtro?): Observable<any> {
		const url = `/oportunidade`
		return this.apiService.get(url, filtro)
	}

	get(id: number): Observable<any> {
		const url = `/oportunidade/${id}`
		return this.apiService.get(url)
	}

	getById_agenda(id: number): Observable<any> {
		const url = `/oportunidade/agenda/${id}`
		return this.apiService.get(url)
	}

	post(form): Observable<any> {
		return this.apiService.post('/oportunidade', form)
	}

	put(id: number, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/oportunidade/' + id, form)
	}

	delete(id): Observable<any> {
		return this.apiService.delete('/oportunidade/' + id)
	}

	getNotificacoes(filtro?): Observable<any> {
		const url = `/oportunidade-notificacoes`
		return this.apiService.get(url, filtro)
	}
}
