import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class TratamentosService {
	constructor(private apiService: ApiService) {}

	post(form): Observable<any> {
		return this.apiService.post('/tratamentos', form)
	}

	get(id: string): Observable<any> {
		const url = `/tratamentos/${id}`
		return this.apiService.get(url)
	}

	getAll(filtro?): Observable<any> {
		const url = `/tratamentos`
		return this.apiService.get(url, filtro)
	}

	getAllFilter(filtro?): Observable<any> {
		const url = `/tratamentos-filter`
		return this.apiService.get(url, filtro)
	}

	getAllSelect(filtro?): Observable<any> {
		const url = `/tratamentos-select`
		return this.apiService.get(url, filtro)
	}

	put(id: string, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/tratamentos/' + id, form)
	}

	delete(id) {
		return this.apiService.delete('/tratamentos/' + id)
	}

	restore(id): Observable<any> {
		return this.apiService.get('/tratamentos/reativar/' + id)
	}
}
