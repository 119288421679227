import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class AtendimentoFotosService {
    constructor(
        private apiService: ApiService
    ) {}

    post(id_agenda,id_cliente,id_atendimento_tratamento, form) : Observable<any> {
        return this.apiService.post(`/atendimento-fotos?id_agenda=${id_agenda}&id_cliente=${id_cliente}&id_atendimento_tratamento=${id_atendimento_tratamento}`, form);
    }
    
    getAll( form ): Observable<any> {
        let url : string = `/atendimento-fotos`
        return this.apiService.get(url, form)
    }

    deleteAnexos(id) {
        return this.apiService.delete('/atendimento-fotos/'+id)
    }


    postFotosCliente(id_cliente, form) : Observable<any> {
        return this.apiService.post(`/atendimento-fotos-cliente?id_cliente=${id_cliente}`, form);
    }
    
    getAllFotosCliente( form ): Observable<any> {
        let url : string = `/atendimento-fotos-cliente`
        return this.apiService.get(url, form)
    }

    deleteAnexosFotosCliente(id) {
        return this.apiService.delete('/atendimento-fotos-cliente/'+id)
    }




}