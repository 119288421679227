import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'

import {
	ApiService,
	FuncionariosService,
	EstadosService,
	CidadesService,
	TipoFuncionariosService,
	ClinicaService,
	TratamentosService,
	PermissoesService,
	ConveniosService,
	MessageService,
	CategoriasTratamentosService,
	FornecedoresService,
	FormaPagamentoService,
	ContasBancariasService,
	BancosService,
	CategoriasFinanceirasService,
	ProdutosService,
	PaisesService,
	TratamentoCustoFixoService,
	TratamentoCustoMaoObraService,
	TratamentoCustoInsumoService,
	TratamentoRecomendacoesService,
	TratamentoAuxiliarService,
	TratamentoConvenioService,
	TratamentoPacoteService,
	ClientesService,
	ComprasService,
	MedidasService,
	FormaRecebimentoService,
	AgendaService,
	ConfiguracoesAgendaService,
	DiasSemanaAgendaService,
	ModalService,
	OrcamentosService,
	SalasService,
	VendasService,
	DocumentosService,
	CategoriasReceitasService,
	ReceitasService,
	ReceituariosService,
	AtendimentoExamesService,
	AtendimentoFotosService,
	AtendimentoObservacoesService,
	AtendimentoReceituariosService,
	AtendimentoService,
	ProntuariosService,
	AtendimentoProntuariosService,
	AtendimentoInsumosAdicionaisService,
	AnamneseService,
	CaixaService,
	TipoAnamneseRespostaService,
	ConfiguracoesVendaService,
	DestinoService,
	TermoService,
	DestinoCompensacaoService,
	FechamentoCaixaService,
	ComissoesProfissionaisService,
	ComissoesAuxiliaresService,
	CreditosService,
	LogCaixaService,
	LogAgendaService,
	LogVendaService,
	AvisoAgendaService,
	ContatoService,
	OportunidadeTratamentosService,
	OportunidadesService,
	OportunidadeInteracoesService,
	DeOndeClienteVeioService,
	OrcamentoNotificacoesService,
	AvisoProfissionalOrcamentoService,
	ExcelService,
	ProtocolosService
} from './services'

@NgModule({
	imports: [CommonModule, HttpClientModule],
	providers: [
		ApiService,
		FuncionariosService,
		EstadosService,
		CidadesService,
		TipoFuncionariosService,
		ClinicaService,
		TratamentosService,
		PermissoesService,
		ConveniosService,
		MessageService,
		CategoriasTratamentosService,
		FornecedoresService,
		FormaPagamentoService,
		ContasBancariasService,
		BancosService,
		CategoriasFinanceirasService,
		ProdutosService,
		PaisesService,
		TratamentoCustoFixoService,
		TratamentoCustoMaoObraService,
		TratamentoCustoInsumoService,
		TratamentoRecomendacoesService,
		TratamentoAuxiliarService,
		TratamentoConvenioService,
		TratamentoPacoteService,
		ClientesService,
		ComprasService,
		MedidasService,
		FormaRecebimentoService,
		AgendaService,
		ConfiguracoesAgendaService,
		DiasSemanaAgendaService,
		ModalService,
		OrcamentosService,
		SalasService,
		VendasService,
		DocumentosService,
		CategoriasReceitasService,
		ReceitasService,
		ReceituariosService,
		AtendimentoExamesService,
		AtendimentoFotosService,
		AtendimentoObservacoesService,
		AtendimentoReceituariosService,
		AtendimentoService,
		ProntuariosService,
		AtendimentoProntuariosService,
		AtendimentoInsumosAdicionaisService,
		AnamneseService,
		CaixaService,
		TipoAnamneseRespostaService,
		ConfiguracoesVendaService,
		DestinoService,
		TermoService,
		DestinoCompensacaoService,
		FechamentoCaixaService,
		ComissoesProfissionaisService,
		ComissoesAuxiliaresService,
		CreditosService,
		LogCaixaService,
		LogAgendaService,
		LogVendaService,
		AvisoAgendaService,
		ContatoService,
		OportunidadeTratamentosService,
		OportunidadesService,
		OportunidadeInteracoesService,
		DeOndeClienteVeioService,
		OrcamentoNotificacoesService,
		AvisoProfissionalOrcamentoService,
		ExcelService,
		ProtocolosService
	],
	declarations: []
})
export class CoreModule {}
