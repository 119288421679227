import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'

@Injectable()
export class MessageService {
	private messageSourceOne = new Subject<string>()
	currentMessageOne = this.messageSourceOne.asObservable()

	private messageRefreshTratamentoConvenio = new Subject<string>()
	currentRefreshTratamentoConvenio = this.messageRefreshTratamentoConvenio.asObservable()

	private messageItemCompra = new Subject<string>()
	currentItemCompra = this.messageItemCompra.asObservable()

	private messageListaItensCompra = new Subject<string>()
	currentListaItensCompra = this.messageListaItensCompra.asObservable()

	private messageProdutosAdicionados = new Subject<string>()
	currentProdutosAdicionados = this.messageProdutosAdicionados.asObservable()

	private messageFormaPagamentoCompra = new Subject<string>()
	currentFormaPagamentoCompra = this.messageFormaPagamentoCompra.asObservable()

	private messageFormaPagamentoAdicionados = new Subject<string>()
	currentFormaPagamentoAdicionados = this.messageFormaPagamentoAdicionados.asObservable()

	private messageDadosCompra = new Subject<string>()
	currentDadosCompra = this.messageDadosCompra.asObservable()

	private messageDadosCompraPagamentosAndItem = new Subject<string>()
	currentDadosCompraPagamentosAndItem = this.messageDadosCompraPagamentosAndItem.asObservable()

	private messageValorRestante = new Subject<string>()
	currentValorRestante = this.messageValorRestante.asObservable()

	private messageDadosCompraToCadastroCompras = new Subject<string>()
	currentDadosCompraToCadastroCompras = this.messageDadosCompraToCadastroCompras.asObservable()

	private messageTratamentoOrcamento = new Subject<string>()
	currentTratamentoOrcamento = this.messageTratamentoOrcamento.asObservable()

	private messageTratamentoContato = new Subject<string>()
	currentTratamentoContato = this.messageTratamentoContato.asObservable()

	private messageInteracaoContato = new Subject<string>()
	currentInteracaoContato = this.messageInteracaoContato.asObservable()

	private messageListaTratamentosOrcamento = new Subject<string>()
	currentListaTratamentosOrcamento = this.messageListaTratamentosOrcamento.asObservable()

	private messageListaTratamentosContato = new Subject<string>()
	currentListaTratamentosContato = this.messageListaTratamentosContato.asObservable()

	private messageListaInteracaoContato = new Subject<string>()
	currentListaInteracaoContato = this.messageListaInteracaoContato.asObservable()

	private messageDadosToAddTratamentos = new Subject<string>()
	currentDadosToAddTratamentos = this.messageDadosToAddTratamentos.asObservable()

	private messageProdutoOrcamento = new Subject<string>()
	currentProdutoOrcamento = this.messageProdutoOrcamento.asObservable()

	private messageListaProdutosOrcamento = new Subject<string>()
	currentListaProdutosOrcamento = this.messageListaProdutosOrcamento.asObservable()

	private messageTratamentosAdicionados = new Subject<string>()
	currentTratamentosAdicionados = this.messageTratamentosAdicionados.asObservable()

	private messageProdutosOrcamentoAdicionados = new Subject<string>()
	currentProdutosOrcamentoAdicionados = this.messageProdutosOrcamentoAdicionados.asObservable()

	private messageDadosPacienteOrcamento = new Subject<string>()
	currentDadosPacienteOrcamento = this.messageDadosPacienteOrcamento.asObservable()

	private messageParamsToAgendar = new Subject<string>()
	currentParamsToAgendar = this.messageParamsToAgendar.asObservable()

	private messageRefreshAgenda = new Subject<string>()
	currentRefreshAgenda = this.messageRefreshAgenda.asObservable()

	private messageTratamentoToCadastroAgendamentoTratamentos = new Subject<string>()
	currentTratamentoToCadastroAgendamentoTratamentos = this.messageTratamentoToCadastroAgendamentoTratamentos.asObservable()

	private messageTratamentoCadastroOrcamento = new Subject<string>()
	currentTratamentoCadastroOrcamento = this.messageTratamentoCadastroOrcamento.asObservable()

	private messageHistoricoCompraProduto = new Subject<string>()
	currentHistoricoCompraProduto = this.messageHistoricoCompraProduto.asObservable()

	private messageAgendamentoToAgendaForEdit = new Subject<string>()
	currentAgendamentoToAgendaForEdit = this.messageAgendamentoToAgendaForEdit.asObservable()

	private messageAgendamentoToAgendaForDelete = new Subject<string>()
	currentAgendamentoToAgendaForDelete = this.messageAgendamentoToAgendaForDelete.asObservable()

	private messageFiltrosToTemplateAgendaDefault = new Subject<string>()
	currentFiltrosToTemplateAgendaDefault = this.messageFiltrosToTemplateAgendaDefault.asObservable()

	private messageParamsToAgendaForCreateAgendamento = new Subject<string>()
	currentParamsToAgendaForCreateAgendamento = this.messageParamsToAgendaForCreateAgendamento.asObservable()

	private messageFiltrosToTemplateAgendaProfissional = new Subject<string>()
	currentFiltrosToTemplateAgendaProfissional = this.messageFiltrosToTemplateAgendaProfissional.asObservable()

	private messageFiltrosToTemplateAgendaSala = new Subject<string>()
	currentFiltrosToTemplateAgendaSala = this.messageFiltrosToTemplateAgendaSala.asObservable()

	private messageFiltrosToTemplateAgendaPaciente = new Subject<string>()
	currentFiltrosToTemplateAgendaPaciente = this.messageFiltrosToTemplateAgendaPaciente.asObservable()

	private messageFiltrosToTemplateAgendaDias = new Subject<string>()
	currentFiltrosToTemplateAgendaDias = this.messageFiltrosToTemplateAgendaDias.asObservable()

	private messageTratamentoVenda = new Subject<string>()
	currentTratamentoVenda = this.messageTratamentoVenda.asObservable()

	private messageListaTratamentosVenda = new Subject<string>()
	currentListaTratamentosVenda = this.messageListaTratamentosVenda.asObservable()

	private messageProdutoVenda = new Subject<string>()
	currentProdutoVenda = this.messageProdutoVenda.asObservable()

	private messageListaProdutosVenda = new Subject<string>()
	currentListaProdutosVenda = this.messageListaProdutosVenda.asObservable()

	private messageProdutosVendaAdicionados = new Subject<string>()
	currentProdutosVendaAdicionados = this.messageProdutosVendaAdicionados.asObservable()

	private messageDadosPacienteVenda = new Subject<string>()
	currentDadosPacienteVenda = this.messageDadosPacienteVenda.asObservable()

	private messageTratamentoCadastroVenda = new Subject<string>()
	currentTratamentoCadastroVenda = this.messageTratamentoCadastroVenda.asObservable()

	private messageFormaRecebimentoVenda = new Subject<string>()
	currentFormaRecebimentoCompra = this.messageFormaRecebimentoVenda.asObservable()

	private messageDadosToAddFormaRecebimento = new Subject<string>()
	currentDadosToAddFormaRecebimento = this.messageDadosToAddFormaRecebimento.asObservable()

	private messageListaFormasRecebimentoToCadastroVendas = new Subject<string>()
	currentListaFormasRecebimentoToCadastroVendas = this.messageListaFormasRecebimentoToCadastroVendas.asObservable()

	private messageDadosDocumentoToDocumentosRelacionadosImpressao = new Subject<string>()
	currentDadosDocumentoToDocumentosRelacionadosImpressao = this.messageDadosDocumentoToDocumentosRelacionadosImpressao.asObservable()

	private messageDadosReceitaToReceituarioImpressaoA5 = new Subject<string>()
	currentDadosReceitaToReceituarioImpressaoA5 = this.messageDadosReceitaToReceituarioImpressaoA5.asObservable()

	private messageDadosReceitaToReceituarioImpressao = new Subject<string>()
	currentDadosReceitaToReceituarioImpressao = this.messageDadosReceitaToReceituarioImpressao.asObservable()

	private messageRefreshTratamentosToDocumentosRelacionados = new Subject<string>()
	currentRefreshTratamentosToDocumentosRelacionados = this.messageRefreshTratamentosToDocumentosRelacionados.asObservable()

	private messageTirarFoto = new Subject<string>()
	currentTirarFoto = this.messageTirarFoto.asObservable()

	private messageAtualizaTratamentosAtendimento = new Subject<string>()
	currentAtualizaTratamentosAtendimento = this.messageAtualizaTratamentosAtendimento.asObservable()

	private messageTratamentoAtendimentoControleToAtendimento = new Subject<string>()
	currentTratamentoAtendimentoControleToAtendimento = this.messageTratamentoAtendimentoControleToAtendimento.asObservable()

	private messageAtualizaTratamentosAtendimentoControle = new Subject<string>()
	currentAtualizaTratamentosAtendimentoControle = this.messageAtualizaTratamentosAtendimentoControle.asObservable()
	private messageCadastroClienteCompleto = new Subject<string>()
	currentCadastroClienteCompleto = this.messageCadastroClienteCompleto.asObservable()

	private messageDadosProntuarioToProntuarioImpressao = new Subject<string>()
	currentDadosProntuarioToProntuarioImpressao = this.messageDadosProntuarioToProntuarioImpressao.asObservable()

	private messageInsumoVenda = new Subject<string>()
	currentInsumoVenda = this.messageInsumoVenda.asObservable()

	private messageListaInsumosVenda = new Subject<string>()
	currentListaInsumosVenda = this.messageListaInsumosVenda.asObservable()

	private messageInsumosVendaAdicionados = new Subject<string>()
	currentInsumosVendaAdicionados = this.messageInsumosVendaAdicionados.asObservable()

	private messageAnamneseAdicionada = new Subject<string>()
	currentAnamneseAdicionada = this.messageAnamneseAdicionada.asObservable()

	private messageDocumentosInvalidosToAtendimentoControlesTratamentos = new Subject<string>()
	currentDocumentosInvalidosToAtendimentoControlesTratamentos = this.messageDocumentosInvalidosToAtendimentoControlesTratamentos.asObservable()

	private messageValidarTratamentoIniciadoToAtendimentoControlesTratamentos = new Subject<string>()
	currentValidarTratamentoIniciadoToAtendimentoControlesTratamentos = this.messageValidarTratamentoIniciadoToAtendimentoControlesTratamentos.asObservable()

	private messageTratamentoIniciadoToAtendimentoTratamentos = new Subject<String>()
	currentTratamentoIniciadoToAtendimentoTratamentos = this.messageTratamentoIniciadoToAtendimentoTratamentos.asObservable()

	private messageExcluirDocumentoRelacionado = new Subject<String>()
	currentExcluirDocumentoRelacionado = this.messageExcluirDocumentoRelacionado.asObservable()

	private messageValidarIniciarTratamentoToDocumentosRelacionados = new Subject<string>()
	currentValidarIniciarTratamentoToDocumentosRelacionados = this.messageValidarIniciarTratamentoToDocumentosRelacionados.asObservable()

	private messageValidarIniciarTodosTratamentosToDocumentosRelacionados = new Subject<string>()
	currentValidarIniciarTodosTratamentosToDocumentosRelacionados = this.messageValidarIniciarTodosTratamentosToDocumentosRelacionados.asObservable()

	private messageValidarIniciarTratamentoToAtendimentoControle = new Subject<string>()
	currentValidarIniciarTratamentoToAtendimentoControle = this.messageValidarIniciarTratamentoToAtendimentoControle.asObservable()

	private messageValidarIniciarTodosTratamentosToAtendimentoControle = new Subject<string>()
	currentValidarIniciarTodosTratamentosToAtendimentoControle = this.messageValidarIniciarTodosTratamentosToAtendimentoControle.asObservable()

	private messageAtualizarTratamentoEditado = new Subject<string>()
	currentAtualizarTratamentoEditado = this.messageAtualizarTratamentoEditado.asObservable()

	private messageDadosToEditTratamentos = new Subject<string>()
	currentDadosToEditTratamentos = this.messageDadosToEditTratamentos.asObservable()

	private messageDadosToEditProduto = new Subject<string>()
	currentDadosToEditProduto = this.messageDadosToEditProduto.asObservable()

	private messageAtualizaValorProdutosToAtendimento = new Subject<string>()
	currentAtualizaValorProdutosToAtendimento = this.messageAtualizaValorProdutosToAtendimento.asObservable()

	private messageAtualizaInsumosToAtendimento = new Subject<string>()
	currentAtualizaInsumosToAtendimento = this.messageAtualizaInsumosToAtendimento.asObservable()

	private messageTodosTratamentosFinalizadosToAtendimento = new Subject<string>()
	currentTodosTratamentosFinalizadosToAtendimento = this.messageTodosTratamentosFinalizadosToAtendimento.asObservable()

	private messageTratamentoEditadoToCadastroVenda = new Subject<string>()
	currentTratamentoEditadoToCadastroVenda = this.messageTratamentoEditadoToCadastroVenda.asObservable()

	private messageFechaResponderAnamnese = new Subject<string>()
	currentFechaResponderAnamnese = this.messageFechaResponderAnamnese.asObservable()

	private messageAtendimentoclienteToListaImportanteAnamnese = new Subject<string>()

	private messageRefreshTratamentosToAtendimentoTratamentos = new Subject<string>()
	currentRefreshTratamentosToAtendimentoTratamentos = this.messageRefreshTratamentosToAtendimentoTratamentos.asObservable()

	private messageTratamentoCreditoToCadastroVendaTratamentosCreditos = new Subject<string>()
	currentTratamentoCreditoToCadastroVendaTratamentosCreditos = this.messageTratamentoCreditoToCadastroVendaTratamentosCreditos.asObservable()

	private messageIdClienteToCreditosCliente = new Subject<string>()
	currentIdClienteToCreditosCliente = this.messageIdClienteToCreditosCliente.asObservable()

	private messageExcluirTratamentosAtendimento = new Subject<string>()
	currentExcluirTratamentosAtendimento = this.messageExcluirTratamentosAtendimento.asObservable()

	private messageDadosAtendimentoToModalDadosAtendimento = new Subject<any>()
	currentDadosAtendimentoToModalDadosAtendimento = this.messageDadosAtendimentoToModalDadosAtendimento.asObservable()

	private messageIdClienteToHistoricoAtendimentos = new Subject<string>()
	currentIdClienteToHistoricoAtendimentos = this.messageIdClienteToHistoricoAtendimentos.asObservable()

	private messageIdClienteToHistoricoCompras = new Subject<string>()
	currentIdClienteToHistoricoCompras = this.messageIdClienteToHistoricoCompras.asObservable()

	private messageFormaPagamentoVendaExistente = new Subject<string>()
	currentFormaPagamentoVendaExistente = this.messageFormaPagamentoVendaExistente.asObservable()

	private messageAtualizarInsumosInTratamentosCustosInsumos = new Subject<string>()
	currentAtualizarInsumosInTratamentosCustosInsumos = this.messageAtualizarInsumosInTratamentosCustosInsumos.asObservable()

	private messageInsumosCadastradosToCadastroCustoInsumoTratamento = new Subject<string>()
	currentInsumosCadastradosToCadastroCustoInsumoTratamento = this.messageInsumosCadastradosToCadastroCustoInsumoTratamento.asObservable()

	private messageInsumoTratamentoEditToCadastroCustoInsumoTratamento = new Subject<string>()
	currentInsumoTratamentoEditToCadastroCustoInsumoTratamento = this.messageInsumoTratamentoEditToCadastroCustoInsumoTratamento.asObservable()

	private messageListaFormasRecebimentoToCadastroVendaFormasRecebimento = new Subject<string>()
	currentListaFormasRecebimentoToCadastroVendaFormasRecebimento = this.messageListaFormasRecebimentoToCadastroVendaFormasRecebimento.asObservable()

	private messageDadosToListasFechamentoCaixa = new Subject<string>()
	currentDadosToListasFechamentoCaixa = this.messageDadosToListasFechamentoCaixa.asObservable()

	private messageListarItensCompras = new Subject<string>()
	currentListarItensCompras = this.messageListarItensCompras.asObservable()

	private messageListarFormasPagamentoCompras = new Subject<string>()
	currentListarFormasPagamentoCompras = this.messageListarFormasPagamentoCompras.asObservable()

	private messageDadosPacienteCompra = new Subject<string>()
	currentDadosPacienteCompra = this.messageDadosPacienteCompra.asObservable()

	private messageListaFormasPagamentoToCadastroCompras = new Subject<string>()
	currentListaFormasPagamentoToCadastroCompras = this.messageListaFormasPagamentoToCadastroCompras.asObservable()

	private messageListaFormasPagamentoToCadastroCompraFormasPagamento = new Subject<string>()
	currentListaFormasPagamentoToCadastroCompraFormasPagamento = this.messageListaFormasPagamentoToCadastroCompraFormasPagamento.asObservable()

	private messageDadosToAddFormaPagamento = new Subject<string>()
	currentDadosToAddFormaPagamento = this.messageDadosToAddFormaPagamento.asObservable()

	private messageFormaPagamentoVenda = new Subject<string>()
	currentFormaPagamentoVenda = this.messageFormaPagamentoVenda.asObservable()

	private messageDadosToFechamentoCaixaDestinadorDestinosCompensacao = new Subject<string>()
	currentDadosToFechamentoCaixaDestinadorDestinosCompensacao = this.messageDadosToFechamentoCaixaDestinadorDestinosCompensacao.asObservable()

	private messageAtualizarListaToFechamentoCaixaDestinaveisDestinosCompensacao = new Subject<string>()
	currentAtualizarListaToFechamentoCaixaDestinaveisDestinosCompensacao = this.messageAtualizarListaToFechamentoCaixaDestinaveisDestinosCompensacao.asObservable()

	private messageDestinadoDestinoCompensacao = new Subject<string>()
	currentDestinadoDestinoCompensacao = this.messageDestinadoDestinoCompensacao.asObservable()

	private messageDestinadosToFechamentoCaixaDestinaveisDestinosCompensacao = new Subject<string>()
	currentDestinadosToFechamentoCaixaDestinaveisDestinosCompensacao = this.messageDestinadosToFechamentoCaixaDestinaveisDestinosCompensacao.asObservable()

	private messageDadosToFechamentoCaixaDestinadorContasBancarias = new Subject<string>()
	currentDadosToFechamentoCaixaDestinadorContasBancarias = this.messageDadosToFechamentoCaixaDestinadorContasBancarias.asObservable()

	private messageDestinadoContaBancaria = new Subject<string>()
	currentDestinadoContaBancaria = this.messageDestinadoContaBancaria.asObservable()

	private messageAtualizarListaToFechamentoCaixaDestinaveisContasBancarias = new Subject<string>()
	currentAtualizarListaToFechamentoCaixaDestinaveisContasBancarias = this.messageAtualizarListaToFechamentoCaixaDestinaveisContasBancarias.asObservable()

	private messageDestinadosToFechamentoCaixaDestinaveisContasBancarias = new Subject<string>()
	currentDestinadosToFechamentoCaixaDestinaveisContasBancarias = this.messageDestinadosToFechamentoCaixaDestinaveisContasBancarias.asObservable()

	private messageBloquearDatasToFechamentoCaixa = new Subject<string>()
	currentBloquearDatasToFechamentoCaixa = this.messageBloquearDatasToFechamentoCaixa.asObservable()

	private messageDadosVendaToConfissaoDivida = new Subject<string>()
	currentDadosVendaToConfissaoDivida = this.messageDadosVendaToConfissaoDivida.asObservable()

	private messageOrcamentoToOrcamentosImpressao = new Subject<string>()
	currentOrcamentoToOrcamentosImpressao = this.messageOrcamentoToOrcamentosImpressao.asObservable()

	private messageConfirmacaoTermoGerado = new Subject<string>()
	currentmessageConfirmacaoTermoGerado = this.messageConfirmacaoTermoGerado.asObservable()

	private messageAtualizarNotificacoesSistema = new Subject<string>()
	currentAtualizarNotificacoesSistema = this.messageAtualizarNotificacoesSistema.asObservable()

	private messageAtualizarControleTratamentosToAtendimento = new Subject<string>()
	currentAtualizarControleTratamentosToAtendimento = this.messageAtualizarControleTratamentosToAtendimento.asObservable()

	private messageAtualizarAnexosControleTempo = new Subject<string>()
	currentAtualizarAnexosControleTempo = this.messageAtualizarAnexosControleTempo.asObservable()

	private messageDestinaveisContasBancariasToFechamentoCaixaRevisao = new Subject<string>()

	private messageDestinaveisDestinosCompensacaoToFechamentoCaixaRevisao = new Subject<string>()

	private messageCreditoTratamentoSaldoToConversaoCredito = new Subject<string>()
	currentCreditoTratamentoSaldoToConversaoCredito = this.messageCreditoTratamentoSaldoToConversaoCredito.asObservable()

	private messageAbaterValorCarteiraDigital = new Subject<string>()

	private messagePeriodoCadastroAgenda = new Subject<string>()

	private messageImpressaorelatoriovenda = new Subject<string>()
	
	private messageTratamentoImpressao = new Subject<string>()

	private messageCsvrelatoriovenda = new Subject<string>()

	private messageImpressaoRelatorioOrcamento = new Subject<string>()

	private messageAbaterValorCarteiraDigitalToFormaRecebimento = new Subject<string>()

	private messageDevolverValorCarteiraDigitalToFormaRecebimento = new Subject<string>()

	private messageBloquearInserirItens = new Subject<string>()

	private messageFiltroRelatorioVendasToRelatorioVendas = new Subject<string>()

	private messageAtualizarMovimentosHistoricoCaixa = new Subject<string>()

	private messageCompensacaoFechamentosAnteriores = new Subject<string>()

	private messageCompensacaoFutura = new Subject<string>()

	private messageMovimentosDestinaveis = new Subject<string>()

	private messageFornecedorCadastradoToCadastroCompras = new Subject<string>()

	private messageCaixaToDuplicataImpressao = new Subject<string>()

	private messageCaixaToDuplicataImpressaoPosVenda = new Subject<string>()

	private messageDadosToImpressaoRecibo = new Subject<string>()

	private messageImpressao = new Subject<string>()

	private messageDadosToImpressaoAnamnese = new Subject<string>()

	private messageProdutosToProdutosImpressao = new Subject<string>()

	private messageClientesToClientesImpressao = new Subject<string>()

	private messageVendasToRelatorioVendasImpressao = new Subject<string>()
	
	private messageTratamentosToTratamentosImpressao = new Subject<string>()

	private messageScrollToNavbar = new Subject<string>()

	private messageCaixaHistoricoToCaixaHistoricoImpressao = new Subject<string>()

	private messageCaixaVendasReceberToCaixaVendasReceber = new Subject<string>()

	private messageCaixaContasPagarToCaixaContasPagarImpressao = new Subject<string>()

	private messageVendasDiariasFechamentoCaixaToRelatorioVendasDiariasFechamentoCaixaImpressao = new Subject<string>()

	private messageVendasDiariasToRelatorioVendasDiariasFechamentoCaixaImpressao = new Subject<string>()

	private messageProcedimentosAtendidosToRelatorioProcedimentosAtendidosImpressao = new Subject<string>()
	
	private messageAniversariantesToRelatorioAniversariantesImpressao = new Subject<string>()

	private messageProcedimentosVendidosToRelatorioProcedimentosVendidosImpressao = new Subject<string>()

	private messageComissoesProfissionaisToRelatorioComissaoProfissionalImpressao = new Subject<string>()
	
	private messageComissoesAuxiliaresToRelatorioComissaoAuxiliarImpressao = new Subject<string>()

	private messageDestinosChequesToRelatorioDestinosChequesImpressao = new Subject<string>()

	private messageRelatorioContasToRelatorioContasImpressao = new Subject<string>()

	private messageFiltroToRelatorioVendasDiarias = new Subject<string>()

	private messageTotalEntradasToRelatorioVendasDiarias = new Subject<string>()

	private messageTotalEntradasFuturasToRelatorioVendasDiarias = new Subject<string>()

	private messageTotalSaidasToRelatorioVendasDiarias = new Subject<string>()

	private messageClienteToClienteCadastroImpressao = new Subject<string>()

	private messageRefreshHistoricoAtendimentos = new Subject<string>()

	private messageAtualizarFiltroOrcamento = new Subject<string>()

	send_messageOne(message) {
		this.messageSourceOne.next(message)
	}

	get_messageOne(): Observable<any> {
		return this.messageSourceOne.asObservable()
	}

	sendRefreshTratamentoConvenio(message) {
		this.messageRefreshTratamentoConvenio.next(message)
	}

	getRefreshTratamentoConvenio(): Observable<any> {
		return this.messageRefreshTratamentoConvenio.asObservable()
	}

	sendFiltroRelatorioVendasToRelatorioVendas(message) {
		this.messageFiltroRelatorioVendasToRelatorioVendas.next(message)
	}

	getFiltroRelatorioVendasToRelatorioVendas(): Observable<any> {
		return this.messageFiltroRelatorioVendasToRelatorioVendas.asObservable()
	}

	sendTratamentoImpressao(message) {
		this.messageTratamentoImpressao.next(message)
	}

	getTratamentoImpressao(): Observable<any> {
		return this.messageTratamentoImpressao.asObservable()
	}

	sendImpressaorelatoriovenda(message) {
		this.messageImpressaorelatoriovenda.next(message)
	}

	getImpressaorelatoriovenda(): Observable<any> {
		return this.messageImpressaorelatoriovenda.asObservable()
	}

	sendCsvrelatoriovenda(message) {
		this.messageCsvrelatoriovenda.next(message)
	}

	getCsvrelatoriovenda(): Observable<any> {
		return this.messageCsvrelatoriovenda.asObservable()
	}

	sendImpressaoRelatorioOrcamento(message) {
		this.messageImpressaoRelatorioOrcamento.next(message)
	}

	getImpressaoRelatorioOrcamento(): Observable<any> {
		return this.messageImpressaoRelatorioOrcamento.asObservable()
	}

	sendItemCompra(message) {
		this.messageItemCompra.next(message)
	}

	getItemCompra(): Observable<any> {
		return this.messageItemCompra.asObservable()
	}

	sendListaItensCompra(message) {
		this.messageListaItensCompra.next(message)
	}

	getListaItensCompra(): Observable<any> {
		return this.messageListaItensCompra.asObservable()
	}

	sendProdutosAdicionados(message) {
		this.messageProdutosAdicionados.next(message)
	}

	getProdutosAdicionados(): Observable<any> {
		return this.messageProdutosAdicionados.asObservable()
	}

	sendFormaPagamentoCompra(message) {
		this.messageFormaPagamentoCompra.next(message)
	}

	getFormaPagamentoCompra(): Observable<any> {
		return this.messageFormaPagamentoCompra.asObservable()
	}

	sendFormaPagamentoAdicionados(message) {
		this.messageFormaPagamentoAdicionados.next(message)
	}

	getFormaPagamentoAdicionados(): Observable<any> {
		return this.messageFormaPagamentoAdicionados.asObservable()
	}

	sendDadosCompraToPagamentosAndItem(message) {
		this.messageDadosCompraPagamentosAndItem.next(message)
	}

	getDadosCompraToPagamentosAndItem(): Observable<any> {
		return this.messageDadosCompraPagamentosAndItem.asObservable()
	}

	sendValorRestante(message) {
		this.messageValorRestante.next(message)
	}

	getValorRestante(): Observable<any> {
		return this.messageValorRestante.asObservable()
	}

	sendDadosCompraToCadastroCompras(message) {
		this.messageDadosCompraToCadastroCompras.next(message)
	}

	getDadosCompraToCadastroCompras(): Observable<any> {
		return this.messageDadosCompraToCadastroCompras.asObservable()
	}

	sendParamsToAgendar(message) {
		this.messageParamsToAgendar.next(message)
	}

	getParamsToAgendar(): Observable<any> {
		return this.messageParamsToAgendar.asObservable()
	}

	sendRefreshAgenda(message) {
		this.messageRefreshAgenda.next(message)
	}

	getRefreshAgenda(): Observable<any> {
		return this.messageRefreshAgenda.asObservable()
	}

	sendTratamentoOrcamento(message) {
		this.messageTratamentoOrcamento.next(message)
	}

	getTratamentoOrcamento(): Observable<any> {
		return this.messageTratamentoOrcamento.asObservable()
	}

	sendTratamentoContato(message) {
		this.messageTratamentoContato.next(message)
	}

	getTratamentoContato(): Observable<any> {
		return this.messageTratamentoContato.asObservable()
	}

	sendInteracaoContato(message) {
		this.messageInteracaoContato.next(message)
	}

	getInteracaoContato(): Observable<any> {
		return this.messageInteracaoContato.asObservable()
	}

	sendListaTratamentosOrcamento(message) {
		this.messageListaTratamentosOrcamento.next(message)
	}

	getListaTratamentosOrcamento(): Observable<any> {
		return this.messageListaTratamentosOrcamento.asObservable()
	}

	sendListaTratamentosContato(message) {
		this.messageListaTratamentosContato.next(message)
	}

	getListaTratamentosContato(): Observable<any> {
		return this.messageListaTratamentosContato.asObservable()
	}

	sendListaInteracaoContato(message) {
		this.messageListaInteracaoContato.next(message)
	}

	getListaInteracaoContato(): Observable<any> {
		return this.messageListaInteracaoContato.asObservable()
	}

	sendTratamentosAdicionados(message) {
		this.messageTratamentosAdicionados.next(message)
	}

	getTratamentosAdicionados(): Observable<any> {
		return this.messageTratamentosAdicionados.asObservable()
	}

	sendDadosToAddTratamentos(message) {
		this.messageDadosToAddTratamentos.next(message)
	}

	getDadosToAddTratamentos(): Observable<any> {
		return this.messageDadosToAddTratamentos.asObservable()
	}

	sendProdutoOrcamento(message) {
		this.messageProdutoOrcamento.next(message)
	}

	getProdutoOrcamento(): Observable<any> {
		return this.messageProdutoOrcamento.asObservable()
	}

	sendListaProdutosOrcamento(message) {
		this.messageListaProdutosOrcamento.next(message)
	}

	getListaProdutosOrcamento(): Observable<any> {
		return this.messageListaProdutosOrcamento.asObservable()
	}

	sendTProdutosOrcamentoAdicionados(message) {
		this.messageProdutosOrcamentoAdicionados.next(message)
	}

	getProdutosOrcamentoAdicionados(): Observable<any> {
		return this.messageProdutosOrcamentoAdicionados.asObservable()
	}

	sendDadosPacienteOrcamento(message) {
		this.messageDadosPacienteOrcamento.next(message)
	}

	getDadosPacienteOrcamento(): Observable<any> {
		return this.messageDadosPacienteOrcamento.asObservable()
	}

	sendTratamentoToCadastroAgendamentoTratamentos(message) {
		this.messageTratamentoToCadastroAgendamentoTratamentos.next(message)
	}

	getTratamentoToCadastroAgendamentoTratamentos(): Observable<any> {
		return this.messageTratamentoToCadastroAgendamentoTratamentos.asObservable()
	}

	sendListaTratamentosCadastroAgendamento(message) {
		this.messageTratamentoCadastroOrcamento.next(message)
	}

	getListaTratamentosCadastroAgendamento(): Observable<any> {
		return this.messageTratamentoCadastroOrcamento.asObservable()
	}

	sendHistoricoCompraProduto(message) {
		this.messageHistoricoCompraProduto.next(message)
	}

	getHistoricoCompraProduto(): Observable<any> {
		return this.messageHistoricoCompraProduto.asObservable()
	}

	sendParamsToAgendaForCreateAgendamento(message) {
		this.messageParamsToAgendaForCreateAgendamento.next(message)
	}

	getParamsToAgendaForCreateAgendamento(): Observable<any> {
		return this.messageParamsToAgendaForCreateAgendamento.asObservable()
	}

	sendAgendamentoToAgendaForEdit(message) {
		this.messageAgendamentoToAgendaForEdit.next(message)
	}

	getAgendamentoToAgendaForEdit(): Observable<any> {
		return this.messageAgendamentoToAgendaForEdit.asObservable()
	}

	sendAgendamentoToAgendaForDelete(message) {
		this.messageAgendamentoToAgendaForDelete.next(message)
	}

	getAgendamentoToAgendaForDelete(): Observable<any> {
		return this.messageAgendamentoToAgendaForDelete.asObservable()
	}

	sendFiltrosToTemplateAgendaDefault(message) {
		this.messageFiltrosToTemplateAgendaDefault.next(message)
	}

	getFiltrosToTemplateAgendaDefault(): Observable<any> {
		return this.messageFiltrosToTemplateAgendaDefault.asObservable()
	}

	sendFiltrosToTemplateAgendaProfissional(message) {
		this.messageFiltrosToTemplateAgendaProfissional.next(message)
	}

	getFiltrosToTemplateAgendaProfissional(): Observable<any> {
		return this.messageFiltrosToTemplateAgendaProfissional.asObservable()
	}

	sendFiltrosToTemplateAgendaSala(message) {
		this.messageFiltrosToTemplateAgendaSala.next(message)
	}

	getFiltrosToTemplateAgendaSala(): Observable<any> {
		return this.messageFiltrosToTemplateAgendaSala.asObservable()
	}

	sendFiltrosToTemplateAgendaCliente(message) {
		this.messageFiltrosToTemplateAgendaPaciente.next(message)
	}

	getFiltrosToTemplateAgendaCliente(): Observable<any> {
		return this.messageFiltrosToTemplateAgendaPaciente.asObservable()
	}

	sendFiltrosToTemplateAgendaDias(message) {
		this.messageFiltrosToTemplateAgendaDias.next(message)
	}

	getFiltrosToTemplateAgendaDias(): Observable<any> {
		return this.messageFiltrosToTemplateAgendaDias.asObservable()
	}

	sendTratamentoVenda(message) {
		this.messageTratamentoVenda.next(message)
	}

	getTratamentoVenda(): Observable<any> {
		return this.messageTratamentoVenda.asObservable()
	}

	sendListaTratamentosVenda(message) {
		this.messageListaTratamentosVenda.next(message)
	}

	getListaTratamentosVenda(): Observable<any> {
		return this.messageListaTratamentosVenda.asObservable()
	}

	sendProdutoVenda(message) {
		this.messageProdutoVenda.next(message)
	}

	getProdutoVenda(): Observable<any> {
		return this.messageProdutoVenda.asObservable()
	}

	sendListaProdutosVenda(message) {
		this.messageListaProdutosVenda.next(message)
	}

	getListaProdutosVenda(): Observable<any> {
		return this.messageListaProdutosVenda.asObservable()
	}

	sendProdutosVendaAdicionados(message) {
		this.messageProdutosVendaAdicionados.next(message)
	}

	getProdutosVendaAdicionados(): Observable<any> {
		return this.messageProdutosVendaAdicionados.asObservable()
	}

	sendInsumoVenda(message) {
		this.messageInsumoVenda.next(message)
	}

	getInsumoVenda(): Observable<any> {
		return this.messageInsumoVenda.asObservable()
	}

	sendListaInsumosVenda(message) {
		this.messageListaInsumosVenda.next(message)
	}

	getListaInsumosVenda(): Observable<any> {
		return this.messageListaInsumosVenda.asObservable()
	}

	sendInsumosVendaAdicionados(message) {
		this.messageInsumosVendaAdicionados.next(message)
	}

	getInsumosVendaAdicionados(): Observable<any> {
		return this.messageInsumosVendaAdicionados.asObservable()
	}

	sendDadosClienteVenda(message) {
		this.messageDadosPacienteVenda.next(message)
	}

	getDadosClienteVenda(): Observable<any> {
		return this.messageDadosPacienteVenda.asObservable()
	}

	sendFormaRecebimentoVenda(message) {
		this.messageFormaRecebimentoVenda.next(message)
	}

	getFormaRecebimentoVenda(): Observable<any> {
		return this.messageFormaRecebimentoVenda.asObservable()
	}

	sendDadosToAddFormaRecebimento(message) {
		this.messageDadosToAddFormaRecebimento.next(message)
	}

	getDadosToAddFormaRecebimento(): Observable<any> {
		return this.messageDadosToAddFormaRecebimento.asObservable()
	}

	sendListaFormasRecebimentoToCadastroVendas(message) {
		this.messageListaFormasRecebimentoToCadastroVendas.next(message)
	}

	getListaFormasRecebimentoToCadastroVendas(): Observable<any> {
		return this.messageListaFormasRecebimentoToCadastroVendas.asObservable()
	}

	sendDadosDocumentoToDocumentosRelacionadosImpressao(message) {
		this.messageDadosDocumentoToDocumentosRelacionadosImpressao.next(message)
	}

	getDadosDocumentoToDocumentosRelacionadosImpressao(): Observable<any> {
		return this.messageDadosDocumentoToDocumentosRelacionadosImpressao.asObservable()
	}

	sendDadosReceitaToReceituarioImpressaoA5(message) {
		this.messageDadosReceitaToReceituarioImpressaoA5.next(message)
	}

	getDadosReceitaToReceituarioImpressaoA5(): Observable<any> {
		return this.messageDadosReceitaToReceituarioImpressaoA5.asObservable()
	}

	sendDadosReceitaToReceituarioImpressao(message) {
		this.messageDadosReceitaToReceituarioImpressao.next(message)
	}

	getDadosReceitaToReceituarioImpressao(): Observable<any> {
		return this.messageDadosReceitaToReceituarioImpressao.asObservable()
	}

	sendRefreshTratamentosToDocumentosRelacionados(message) {
		this.messageRefreshTratamentosToDocumentosRelacionados.next(message)
	}

	getRefreshTratamentosToDocumentosRelacionados(): Observable<any> {
		return this.messageRefreshTratamentosToDocumentosRelacionados.asObservable()
	}

	sendTirarFoto(message) {
		this.messageTirarFoto.next(message)
	}

	getTirarFoto(): Observable<any> {
		return this.messageTirarFoto.asObservable()
	}

	sendAtualizaTratamentosAtendimento(message) {
		this.messageAtualizaTratamentosAtendimento.next(message)
	}

	getAtualizaTratamentosAtendimento(): Observable<any> {
		return this.messageAtualizaTratamentosAtendimento.asObservable()
	}

	sendTratamentoAtendimentoControleToAtendimento(message) {
		this.messageTratamentoAtendimentoControleToAtendimento.next(message)
	}

	getTratamentoAtendimentoControleToAtendimento(): Observable<any> {
		return this.messageTratamentoAtendimentoControleToAtendimento.asObservable()
	}

	sendAtualizaTratamentosAtendimentoControle(message) {
		this.messageAtualizaTratamentosAtendimentoControle.next(message)
	}

	getAtualizaTratamentosAtendimentoControle(): Observable<any> {
		return this.messageAtualizaTratamentosAtendimentoControle.asObservable()
	}

	sendCadastroClienteCompleto() {
		this.messageCadastroClienteCompleto.next()
	}

	getCadastroClienteCompleto(): Observable<any> {
		return this.messageCadastroClienteCompleto.asObservable()
	}

	sendDadosProntuarioToProntuarioImpressao(message) {
		this.messageDadosProntuarioToProntuarioImpressao.next(message)
	}

	getDadosProntuarioToProntuarioImpressao(): Observable<any> {
		return this.messageDadosProntuarioToProntuarioImpressao.asObservable()
	}

	sendAnamneseAdicionada(message) {
		this.messageAnamneseAdicionada.next(message)
	}

	getAnamneseAdicionada(): Observable<any> {
		return this.messageAnamneseAdicionada.asObservable()
	}

	sendDocumentosInvalidosToAtendimentoControlesTratamentos(message) {
		this.messageDocumentosInvalidosToAtendimentoControlesTratamentos.next(message)
	}

	getDocumentosInvalidosToAtendimentoControlesTratamentos(): Observable<any> {
		return this.messageDocumentosInvalidosToAtendimentoControlesTratamentos.asObservable()
	}

	sendValidarTratamentoIniciadoToAtendimentoControlesTratamentos(message) {
		this.messageValidarTratamentoIniciadoToAtendimentoControlesTratamentos.next(message)
	}

	getValidarTratamentoIniciadoToAtendimentoControlesTratamentos(): Observable<any> {
		return this.messageValidarTratamentoIniciadoToAtendimentoControlesTratamentos.asObservable()
	}

	sendTratamentoIniciadoToAtendimentoTratamentos(message) {
		this.messageTratamentoIniciadoToAtendimentoTratamentos.next(message)
	}

	getTratamentoIniciadoToAtendimentoTratamentos(): Observable<any> {
		return this.messageTratamentoIniciadoToAtendimentoTratamentos.asObservable()
	}

	sendExcluirDocumentoRelacionado(message) {
		this.messageExcluirDocumentoRelacionado.next(message)
	}

	getExcluirDocumentoRelacionado(): Observable<any> {
		return this.messageExcluirDocumentoRelacionado.asObservable()
	}

	sendValidarIniciarTratamento(message) {
		this.messageValidarIniciarTratamentoToDocumentosRelacionados.next(message)
	}

	getValidarIniciarTratamento(): Observable<any> {
		return this.messageValidarIniciarTratamentoToDocumentosRelacionados.asObservable()
	}

	sendValidarIniciarTratamentoToAtendimentoControle(message) {
		this.messageValidarIniciarTratamentoToAtendimentoControle.next(message)
	}

	getValidarIniciarTratamentoToAtendimentoControle(): Observable<any> {
		return this.messageValidarIniciarTratamentoToAtendimentoControle.asObservable()
	}

	sendValidarIniciarTodosTratamentos(message) {
		this.messageValidarIniciarTodosTratamentosToDocumentosRelacionados.next(message)
	}

	getValidarIniciarTodosTratamentos(): Observable<any> {
		return this.messageValidarIniciarTodosTratamentosToDocumentosRelacionados.asObservable()
	}

	sendValidarIniciarTodosTratamentosToAtendimentoControle(message) {
		this.messageValidarIniciarTodosTratamentosToAtendimentoControle.next(message)
	}

	getValidarIniciarTodosTratamentosToAtendimentoControle(): Observable<any> {
		return this.messageValidarIniciarTodosTratamentosToAtendimentoControle.asObservable()
	}

	sendAtualizarTratamentoEditado(message) {
		this.messageAtualizarTratamentoEditado.next(message)
	}

	getAtualizarTratamentoEditado(): Observable<any> {
		return this.messageAtualizarTratamentoEditado.asObservable()
	}

	sendDadosToEditTratamento(message) {
		this.messageDadosToEditTratamentos.next(message)
	}

	getDadosToEditTratamento(): Observable<any> {
		return this.messageDadosToEditTratamentos.asObservable()
	}

	sendDadosToEditProduto(message) {
		this.messageDadosToEditProduto.next(message)
	}

	getDadosToEditProduto(): Observable<any> {
		return this.messageDadosToEditProduto.asObservable()
	}

	sendAtualizaValorProdutosToAtendimento(message) {
		this.messageAtualizaValorProdutosToAtendimento.next(message)
	}

	getAtualizaValorProdutosToAtendimento(): Observable<any> {
		return this.messageAtualizaValorProdutosToAtendimento.asObservable()
	}

	sendAtualizaInsumosToAtendimento(message) {
		this.messageAtualizaInsumosToAtendimento.next(message)
	}

	getAtualizaInsumosToAtendimento(): Observable<any> {
		return this.messageAtualizaInsumosToAtendimento.asObservable()
	}

	sendTodosTratamentosFinalizadosToAtendimento(message) {
		this.messageTodosTratamentosFinalizadosToAtendimento.next(message)
	}

	getTodosTratamentosFinalizadosToAtendimento(): Observable<any> {
		return this.messageTodosTratamentosFinalizadosToAtendimento.asObservable()
	}

	sendTratamentoEditadoToCadastroVenda(message) {
		this.messageTratamentoEditadoToCadastroVenda.next(message)
	}

	getTratamentoEditadoToCadastroVenda(): Observable<any> {
		return this.messageTratamentoEditadoToCadastroVenda.asObservable()
	}

	sendFechaResponderAnamnese(message) {
		this.messageFechaResponderAnamnese.next(message)
	}

	getFechaResponderAnamnese(): Observable<any> {
		return this.messageFechaResponderAnamnese.asObservable()
	}

	sendRefreshTratamentosToAtendimentoTratamentos(message) {
		this.messageRefreshTratamentosToAtendimentoTratamentos.next(message)
	}

	getRefreshTratamentosToAtendimentoTratamentos(): Observable<any> {
		return this.messageRefreshTratamentosToAtendimentoTratamentos.asObservable()
	}

	sendAtendimentoclienteToListaImportanteAnamnese(message) {
		this.messageAtendimentoclienteToListaImportanteAnamnese.next(message)
	}

	getAtendimentoclienteToListaImportanteAnamnese(): Observable<any> {
		return this.messageAtendimentoclienteToListaImportanteAnamnese.asObservable()
	}

	sendTratamentoCredito(message) {
		this.messageTratamentoCreditoToCadastroVendaTratamentosCreditos.next(message)
	}

	getTratamentoCredito(): Observable<any> {
		return this.messageTratamentoCreditoToCadastroVendaTratamentosCreditos.asObservable()
	}

	sendIdClienteToCreditosCliente(message) {
		this.messageIdClienteToCreditosCliente.next(message)
	}

	getIdClienteToCreditosCliente(): Observable<any> {
		return this.messageIdClienteToCreditosCliente.asObservable()
	}

	sendExcluirTratamentosAtendimento(message) {
		this.messageExcluirTratamentosAtendimento.next(message)
	}

	getExcluirTratamentosAtendimento(): Observable<any> {
		return this.messageExcluirTratamentosAtendimento.asObservable()
	}

	sendIdClienteToHistoricoAtendimentos(message) {
		this.messageExcluirTratamentosAtendimento.next(message)
	}

	getIdClienteToHistoricoAtendimentos(): Observable<any> {
		return this.messageExcluirTratamentosAtendimento.asObservable()
	}

	sendDadosAtendimentoToModalDadosAtendimento(message) {
		this.messageDadosAtendimentoToModalDadosAtendimento.next(message)
	}

	getDadosAtendimentoToModalDadosAtendimento(): Observable<any> {
		return this.messageDadosAtendimentoToModalDadosAtendimento.asObservable()
	}

	sendIdClienteToHistoricoCompras(message) {
		this.messageIdClienteToHistoricoCompras.next(message)
	}

	getIdClienteToHistoricoCompras(): Observable<any> {
		return this.messageIdClienteToHistoricoCompras.asObservable()
	}

	sendFormaPagamentoVendaExistente(message) {
		this.messageFormaPagamentoVendaExistente.next(message)
	}

	getFormaPagamentoVendaExistente(): Observable<any> {
		return this.messageFormaPagamentoVendaExistente.asObservable()
	}

	sendAtualizarInsumosInTratamentosCustosInsumos(message) {
		this.messageAtualizarInsumosInTratamentosCustosInsumos.next(message)
	}

	getAtualizarInsumosInTratamentosCustosInsumos(): Observable<any> {
		return this.messageAtualizarInsumosInTratamentosCustosInsumos.asObservable()
	}

	sendInsumosCadastradosToCadastroCustoInsumoTratamento(message) {
		this.messageInsumosCadastradosToCadastroCustoInsumoTratamento.next(message)
	}

	getInsumosCadastradosToCadastroCustoInsumoTratamento(): Observable<any> {
		return this.messageInsumosCadastradosToCadastroCustoInsumoTratamento.asObservable()
	}

	sendInsumoTratamentoEditToCadastroCustoInsumoTratamento(message) {
		this.messageInsumoTratamentoEditToCadastroCustoInsumoTratamento.next(message)
	}

	getInsumoTratamentoEditToCadastroCustoInsumoTratamento(): Observable<any> {
		return this.messageInsumoTratamentoEditToCadastroCustoInsumoTratamento.asObservable()
	}

	sendListaFormasRecebimentoToCadastroVendaFormasRecebimento(message) {
		this.messageListaFormasRecebimentoToCadastroVendaFormasRecebimento.next(message)
	}

	getListaFormasRecebimentoToCadastroVendaFormasRecebimento(): Observable<any> {
		return this.messageListaFormasRecebimentoToCadastroVendaFormasRecebimento.asObservable()
	}

	sendDadosToListasFechamentoCaixa(message) {
		this.messageDadosToListasFechamentoCaixa.next(message)
	}

	getDadosToListasFechamentoCaixa(): Observable<any> {
		return this.messageDadosToListasFechamentoCaixa.asObservable()
	}

	sendListarItensCompra(message) {
		this.messageListarItensCompras.next(message)
	}

	getListarItensCompra(): Observable<any> {
		return this.messageListarItensCompras.asObservable()
	}

	sendListarFormasPagamentoCompra(message) {
		this.messageListarFormasPagamentoCompras.next(message)
	}

	getListarFormasPagamentoCompra(): Observable<any> {
		return this.messageListarFormasPagamentoCompras.asObservable()
	}

	sendDadosPacienteCompra(message) {
		this.messageDadosPacienteCompra.next(message)
	}

	getDadosPacienteCompra(): Observable<any> {
		return this.messageDadosPacienteCompra.asObservable()
	}

	sendListaFormasPagamentoToCadastroCompras(message) {
		this.messageListaFormasPagamentoToCadastroCompras.next(message)
	}

	getListaFormasPagamentoToCadastroCompras(): Observable<any> {
		return this.messageListaFormasPagamentoToCadastroCompras.asObservable()
	}

	sendListaFormasPagamentoToCadastroCompraFormasPagamento(message) {
		this.messageListaFormasPagamentoToCadastroCompraFormasPagamento.next(message)
	}

	getListaFormasPagamentoToCadastroCompraFormasPagamento(): Observable<any> {
		return this.messageListaFormasPagamentoToCadastroCompraFormasPagamento.asObservable()
	}

	sendDadosToAddFormaPagamento(message) {
		this.messageDadosToAddFormaPagamento.next(message)
	}

	getDadosToAddFormaPagamento(): Observable<any> {
		return this.messageDadosToAddFormaPagamento.asObservable()
	}

	sendFormaPagamentoVenda(message) {
		this.messageFormaPagamentoVenda.next(message)
	}

	getFormaPagamentoVenda(): Observable<any> {
		return this.messageFormaPagamentoVenda.asObservable()
	}

	sendDadosToFechamentoCaixaDestinadorDestinosCompensacao(message) {
		this.messageDadosToFechamentoCaixaDestinadorDestinosCompensacao.next(message)
	}

	getDadosToFechamentoCaixaDestinadorDestinosCompensacao(): Observable<any> {
		return this.messageDadosToFechamentoCaixaDestinadorDestinosCompensacao.asObservable()
	}

	sendAtualizarListaToFechamentoCaixaDestinaveisDestinosCompensacao(message) {
		this.messageAtualizarListaToFechamentoCaixaDestinaveisDestinosCompensacao.next(message)
	}

	getAtualizarListaToFechamentoCaixaDestinaveisDestinosCompensacao(): Observable<any> {
		return this.messageAtualizarListaToFechamentoCaixaDestinaveisDestinosCompensacao.asObservable()
	}

	sendDestinadoDestinoCompensacao(message) {
		this.messageDestinadoDestinoCompensacao.next(message)
	}

	getDestinadoDestinoCompensacao(): Observable<any> {
		return this.messageDestinadoDestinoCompensacao.asObservable()
	}

	sendDestinadosToFechamentoCaixaDestinaveisDestinosCompensacao(message) {
		this.messageDestinadosToFechamentoCaixaDestinaveisDestinosCompensacao.next(message)
	}

	getDestinadosToFechamentoCaixaDestinaveisDestinosCompensacao(): Observable<any> {
		return this.messageDestinadosToFechamentoCaixaDestinaveisDestinosCompensacao.asObservable()
	}

	sendDadosToFechamentoCaixaDestinadorContasBancarias(message) {
		this.messageDadosToFechamentoCaixaDestinadorContasBancarias.next(message)
	}

	getDadosToFechamentoCaixaDestinadorContasBancarias(): Observable<any> {
		return this.messageDadosToFechamentoCaixaDestinadorContasBancarias.asObservable()
	}

	sendDestinadoContaBancaria(message) {
		this.messageDestinadoContaBancaria.next(message)
	}

	getDestinadoContaBancaria(): Observable<any> {
		return this.messageDestinadoContaBancaria.asObservable()
	}

	sendAtualizarListaToFechamentoCaixaDestinaveisContasBancarias(message) {
		this.messageAtualizarListaToFechamentoCaixaDestinaveisContasBancarias.next(message)
	}

	getAtualizarListaToFechamentoCaixaDestinaveisContasBancarias(): Observable<any> {
		return this.messageAtualizarListaToFechamentoCaixaDestinaveisContasBancarias.asObservable()
	}

	sendDestinadosToFechamentoCaixaDestinaveisContasBancarias(message) {
		this.messageDestinadosToFechamentoCaixaDestinaveisContasBancarias.next(message)
	}

	getDestinadosToFechamentoCaixaDestinaveisContasBancarias(): Observable<any> {
		return this.messageDestinadosToFechamentoCaixaDestinaveisContasBancarias.asObservable()
	}

	sendBloquearDatasToFechamentoCaixa(message) {
		this.messageBloquearDatasToFechamentoCaixa.next(message)
	}

	getBloquearDatasToFechamentoCaixa(): Observable<any> {
		return this.messageBloquearDatasToFechamentoCaixa.asObservable()
	}

	sendDadosVendaToConfissaoDivida(message) {
		this.messageDadosVendaToConfissaoDivida.next(message)
	}

	getDadosVendaToConfissaoDivida(): Observable<any> {
		return this.messageDadosVendaToConfissaoDivida.asObservable()
	}

	sendOrcamentoToOrcamentosImpressao(message) {
		this.messageOrcamentoToOrcamentosImpressao.next(message)
	}

	getOrcamentoToOrcamentosImpressao(): Observable<any> {
		return this.messageOrcamentoToOrcamentosImpressao.asObservable()
	}

	sendConfirmacaoTermoGerado(message) {
		this.messageConfirmacaoTermoGerado.next(message)
	}

	getConfirmacaoTermoGerado(): Observable<any> {
		return this.messageConfirmacaoTermoGerado.asObservable()
	}

	sendAtualizarNotificacoesSistema(message) {
		this.messageAtualizarNotificacoesSistema.next(message)
	}

	getAtualizarNotificacoesSistema(): Observable<any> {
		return this.messageAtualizarNotificacoesSistema.asObservable()
	}

	sendAtualizarControleTratamentosToAtendimento(message) {
		this.messageAtualizarControleTratamentosToAtendimento.next(message)
	}

	getAtualizarControleTratamentosToAtendimento(): Observable<any> {
		return this.messageAtualizarControleTratamentosToAtendimento.asObservable()
	}

	sendAtualizarAnexosControleTempo(message) {
		this.messageAtualizarAnexosControleTempo.next(message)
	}

	getAtualizarAnexosControleTempo(): Observable<any> {
		return this.messageAtualizarAnexosControleTempo.asObservable()
	}

	sendDestinaveisContasBancariasToFechamentoCaixaRevisao(message) {
		this.messageDestinaveisContasBancariasToFechamentoCaixaRevisao.next(message)
	}

	getDestinaveisContasBancariasToFechamentoCaixaRevisao(): Observable<any> {
		return this.messageDestinaveisContasBancariasToFechamentoCaixaRevisao.asObservable()
	}

	sendDestinaveisDestinosCompensacaoToFechamentoCaixaRevisao(message) {
		this.messageDestinaveisDestinosCompensacaoToFechamentoCaixaRevisao.next(message)
	}

	getDestinaveisDestinosCompensacaoToFechamentoCaixaRevisao(): Observable<any> {
		return this.messageDestinaveisDestinosCompensacaoToFechamentoCaixaRevisao.asObservable()
	}

	sendCreditoTratamentoSaldoToConversaoCredito(message) {
		this.messageCreditoTratamentoSaldoToConversaoCredito.next(message)
	}

	getCreditoTratamentoSaldoToConversaoCredito(): Observable<any> {
		return this.messageCreditoTratamentoSaldoToConversaoCredito.asObservable()
	}

	sendAbaterValorCarteiraDigital(message) {
		this.messageAbaterValorCarteiraDigital.next(message)
	}

	getAbaterValorCarteiraDigital(): Observable<any> {
		return this.messageAbaterValorCarteiraDigital.asObservable()
	}

	sendPeriodoCadastroAgenda(message) {
		this.messagePeriodoCadastroAgenda.next(message)
	}

	getPeriodoCadastroAgenda(): Observable<any> {
		return this.messagePeriodoCadastroAgenda.asObservable()
	}

	sendAbaterValorCarteiraDigitalToFormaRecebimento(message) {
		this.messageAbaterValorCarteiraDigitalToFormaRecebimento.next(message)
	}

	getAbaterValorCarteiraDigitalToFormaRecebimento(): Observable<any> {
		return this.messageAbaterValorCarteiraDigitalToFormaRecebimento.asObservable()
	}

	sendDevolverValorCarteiraDigitalToFormaRecebimento(message) {
		this.messageDevolverValorCarteiraDigitalToFormaRecebimento.next(message)
	}

	getDevolverValorCarteiraDigitalToFormaRecebimento(): Observable<any> {
		return this.messageDevolverValorCarteiraDigitalToFormaRecebimento.asObservable()
	}

	sendBloquearInserirItens(message) {
		this.messageBloquearInserirItens.next(message)
	}

	getBloquearInserirItens(): Observable<any> {
		return this.messageBloquearInserirItens.asObservable()
	}

	sendAtualizarMovimentosHistoricoCaixa(message) {
		this.messageAtualizarMovimentosHistoricoCaixa.next(message)
	}

	getAtualizarMovimentosHistoricoCaixa(): Observable<any> {
		return this.messageAtualizarMovimentosHistoricoCaixa.asObservable()
	}

	sendCompensacaoFechamentosAnteriores(message) {
		this.messageCompensacaoFechamentosAnteriores.next(message)
	}

	getCompensacaoFechamentosAnteriores(): Observable<any> {
		return this.messageCompensacaoFechamentosAnteriores.asObservable()
	}

	sendCompensacaoFutura(message) {
		this.messageCompensacaoFutura.next(message)
	}

	getCompensacaoFutura(): Observable<any> {
		return this.messageCompensacaoFutura.asObservable()
	}

	sendMovimentosDestinaveis(message) {
		this.messageMovimentosDestinaveis.next(message)
	}

	getMovimentosDestinaveis(): Observable<any> {
		return this.messageMovimentosDestinaveis.asObservable()
	}

	sendFornecedorCadastradoToCadastroCompras(message): void {
		this.messageFornecedorCadastradoToCadastroCompras.next(message)
	}

	getFornecedorCadastradoToCadastroCompras(): Observable<any> {
		return this.messageFornecedorCadastradoToCadastroCompras.asObservable()
	}

	sendDadosToDuplicataImpressao(message): void {
		this.messageCaixaToDuplicataImpressao.next(message)
	}

	getDadosToDuplicataImpressao(): Observable<any> {
		return this.messageCaixaToDuplicataImpressao.asObservable()
	}

	sendDadosToImpressaoRecibo(message): void {
		this.messageDadosToImpressaoRecibo.next(message)
	}

	getDadosToImpressaoRecibo(): Observable<any> {
		return this.messageDadosToImpressaoRecibo.asObservable()
	}

	sendImpressao(message): void {
		this.messageImpressao.next(message)
	}

	getImpressao(): Observable<any> {
		return this.messageImpressao.asObservable()
	}

	sendDadosToImpressaoAnamnese(message): void {
		this.messageDadosToImpressaoAnamnese.next(message)
	}

	getDadosToImpressaoAnamnese(): Observable<any> {
		return this.messageDadosToImpressaoAnamnese.asObservable()
	}

	sendDadosToDuplicataImpressaoPosVenda(message): void {
		this.messageCaixaToDuplicataImpressaoPosVenda.next(message)
	}

	getDadosToDuplicataImpressaoPosVenda(): Observable<any> {
		return this.messageCaixaToDuplicataImpressaoPosVenda.asObservable()
	}

	sendProdutosToProdutosImpressao(message): void {
		this.messageProdutosToProdutosImpressao.next(message)
	}

	getProdutosToProdutosImpressao(): Observable<any> {
		return this.messageProdutosToProdutosImpressao.asObservable()
	}

	sendClientesToClientesImpressao(message): void {
		this.messageClientesToClientesImpressao.next(message)
	}

	getClientesToClientesImpressao(): Observable<any> {
		return this.messageClientesToClientesImpressao.asObservable()
	}

	sendVendasToRelatorioVendasImpressao(message): void {
		this.messageVendasToRelatorioVendasImpressao.next(message)
	}

	getVendasToRelatorioVendasImpressao(): Observable<any> {
		return this.messageVendasToRelatorioVendasImpressao.asObservable()
	}
	
	sendTratamentosToTratamentosImpressao(message): void {
		this.messageTratamentosToTratamentosImpressao.next(message)
	}

	getTratamentosToTratamentosImpressao(): Observable<any> {
		return this.messageTratamentosToTratamentosImpressao.asObservable()
	}

	sendScrolltoNavbar(message): void {
		this.messageScrollToNavbar.next(message)
	}

	getScrollToNavbar(): Observable<any> {
		return this.messageScrollToNavbar.asObservable()
	}

	sendCaixaHistoricoToCaixaHistoricoImpressao(message): void {
		this.messageCaixaHistoricoToCaixaHistoricoImpressao.next(message)
	}

	getCaixaHistoricoToCaixaHistoricoImpressao(): Observable<any> {
		return this.messageCaixaHistoricoToCaixaHistoricoImpressao.asObservable()
	}

	sendCaixaVendasReceberToCaixaVendasReceber(message): void {
		this.messageCaixaVendasReceberToCaixaVendasReceber.next(message)
	}

	getCaixaVendasReceberToCaixaVendasReceber(): Observable<any> {
		return this.messageCaixaVendasReceberToCaixaVendasReceber.asObservable()
	}

	sendCaixaContasPagarToCaixaContasPagarImpressao(message): void {
		this.messageCaixaContasPagarToCaixaContasPagarImpressao.next(message)
	}

	getCaixaContasPagarToCaixaContasPagarImpressao(): Observable<any> {
		return this.messageCaixaContasPagarToCaixaContasPagarImpressao.asObservable()
	}

	sendVendasDiariasFechamentoCaixaToRelatorioVendasDiariasFechamentoCaixaImpressao(message): void {
		this.messageVendasDiariasFechamentoCaixaToRelatorioVendasDiariasFechamentoCaixaImpressao.next(message)
	}

	getVendasDiariasFechamentoCaixaToRelatorioVendasDiariasFechamentoCaixaImpressao(): Observable<any> {
		return this.messageVendasDiariasFechamentoCaixaToRelatorioVendasDiariasFechamentoCaixaImpressao.asObservable()
	}

	sendVendasDiariasToRelatorioVendasDiariasFechamentoCaixaImpressao(message): void {
		this.messageVendasDiariasToRelatorioVendasDiariasFechamentoCaixaImpressao.next(message)
	}

	getVendasDiariasToRelatorioVendasDiariasFechamentoCaixaImpressao(): Observable<any> {
		return this.messageVendasDiariasToRelatorioVendasDiariasFechamentoCaixaImpressao.asObservable()
	}

	sendProcedimentosAtendidosToRelatorioProcedimentosAtendidosImpressao(message): void {
		this.messageProcedimentosAtendidosToRelatorioProcedimentosAtendidosImpressao.next(message)
	}

	getProcedimentosAtendidosToRelatorioProcedimentosAtendidosImpressao(): Observable<any> {
		return this.messageProcedimentosAtendidosToRelatorioProcedimentosAtendidosImpressao.asObservable()
	}
	sendAniversariantesToRelatorioAniversariantesImpressao(message): void {
		this.messageAniversariantesToRelatorioAniversariantesImpressao.next(message)
	}

	getAniversariantesToRelatorioAniversariantesImpressao(): Observable<any> {
		return this.messageAniversariantesToRelatorioAniversariantesImpressao.asObservable()
	}

	sendProcedimentosVendidosToRelatorioProcedimentosVendidosImpressao(message): void {
		this.messageProcedimentosVendidosToRelatorioProcedimentosVendidosImpressao.next(message)
	}

	getProcedimentosVendidosToRelatorioProcedimentosVendidosImpressao(): Observable<any> {
	return this.messageProcedimentosVendidosToRelatorioProcedimentosVendidosImpressao.asObservable()
	}
	
	sendComissoesProfissionaisToRelatorioComissaoProfissionalImpressao(message): void {
		this.messageComissoesProfissionaisToRelatorioComissaoProfissionalImpressao.next(message)
	}
	
	getComissoesProfissionaisToRelatorioComissaoProfissionalImpressao(): Observable<any> {
		return this.messageComissoesProfissionaisToRelatorioComissaoProfissionalImpressao.asObservable()
	}
	
	sendComissoesAuxiliaresToRelatorioComissaoAuxiliarImpressao(message): void {
		this.messageComissoesAuxiliaresToRelatorioComissaoAuxiliarImpressao.next(message)
	}
	
	getComissoesAuxiliaresToRelatorioComissaoAuxiliarImpressao(): Observable<any> {
		return this.messageComissoesAuxiliaresToRelatorioComissaoAuxiliarImpressao.asObservable()
	}
	sendDestinosChequesToRelatorioDestinosChequesImpressao(message): void {
		this.messageDestinosChequesToRelatorioDestinosChequesImpressao.next(message)
	}

	getDestinosChequesToRelatorioDestinosChequesImpressao(): Observable<any> {
		return this.messageDestinosChequesToRelatorioDestinosChequesImpressao.asObservable()
	}

	sendRelatorioContasToRelatorioContasImpressao(message): void {
		this.messageRelatorioContasToRelatorioContasImpressao.next(message)
	}

	getRelatorioContasToRelatorioContasImpressao(): Observable<any> {
		return this.messageRelatorioContasToRelatorioContasImpressao.asObservable()
	}

	sendFiltroToRelatorioVendasDiarias(message): void {
		this.messageFiltroToRelatorioVendasDiarias.next(message)
	}

	getFiltroToRelatorioVendasDiarias(): Observable<any> {
		return this.messageFiltroToRelatorioVendasDiarias.asObservable()
	}

	sendTotalEntradasToRelatorioVendasDiarias(message): void {
		this.messageTotalEntradasToRelatorioVendasDiarias.next(message)
	}

	getTotalEntradasToRelatorioVendasDiarias(): Observable<any> {
		return this.messageTotalEntradasToRelatorioVendasDiarias.asObservable()
	}

	sendTotalEntradasFuturasToRelatorioVendasDiarias(message): void {
		this.messageTotalEntradasFuturasToRelatorioVendasDiarias.next(message)
	}

	getTotalEntradasFuturasToRelatorioVendasDiarias(): Observable<any> {
		return this.messageTotalEntradasFuturasToRelatorioVendasDiarias.asObservable()
	}

	sendTotalSaidasToRelatorioVendasDiarias(message): void {
		this.messageTotalSaidasToRelatorioVendasDiarias.next(message)
	}

	getTotalSaidasToRelatorioVendasDiarias(): Observable<any> {
		return this.messageTotalSaidasToRelatorioVendasDiarias.asObservable()
	}

	sendClienteToClienteCadastroImpressao(message): void {
		this.messageClienteToClienteCadastroImpressao.next(message)
	}

	getClienteToClienteCadastroImpressao(): Observable<any> {
		return this.messageClienteToClienteCadastroImpressao.asObservable()
	}

	sendRefreshHistoricoAtendimentos(message): void {
		this.messageRefreshHistoricoAtendimentos.next(message)
	}

	getRefreshHistoricoAtendimentos(): Observable<any> {
		return this.messageRefreshHistoricoAtendimentos.asObservable()
	}

	sendAtualizarFiltroOrcamento(): void {
		this.messageAtualizarFiltroOrcamento.next()
	}

	getAtualizarFiltroOrcamento(): Observable<any> {
		return this.messageAtualizarFiltroOrcamento.asObservable()
	}
}
