import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class ConveniosService {
    constructor(
        private apiService: ApiService
    ) {}
    
    get(id): Observable<any> {
		let url : string = `/convenios/${id}`
		return this.apiService.get(url)
    }
    
    getAll(filtro?): Observable<any> {
		let url : string = `/convenios`
		return this.apiService.get(url, filtro)
    }
    
    restore(id, form) : Observable<any> {
        return this.apiService.post('/convenios/reativar/' + id, form)
    }
    
    post(form) : Observable<any> {
        return this.apiService.post('/convenios', form)
    }
    
    put(id: string, form) : Observable<any> {
		form._method = 'PUT'
        return this.apiService.post('/convenios/' + id, form)
    }
    
    delete(id) {
        return this.apiService.delete('/convenios/' + id)
    }
}