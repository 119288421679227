import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()
export class ComissoesProfissionaisService {
	constructor(private apiService: ApiService) {}

	getComissoesProfissionais(filtro?): Observable<any> {
		return this.apiService.post(`/comissoes-profissionais`, filtro).pipe(
			retry(5),
			catchError(() => {
				return EMPTY
			}),
			shareReplay()
		)
	}

	getRelatorioComissoesProfissionais(filtro?): Observable<any> {
		return this.apiService
			.post(`/relatorio-comissoes-profissionais`, filtro)
			.pipe(
				retry(5),
				catchError(() => {
					return EMPTY
				}),
				shareReplay()
			)
	}

	postComissoesPagas(form): Observable<any> {
		return this.apiService.post('/comissoes-profissionais-pagas', form)
	}

	pdf(form): Observable<any> {
		return this.apiService.post('/comissoes-pdf', form)
	}
}
