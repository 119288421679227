import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class LogCaixaService {
    constructor(
        private apiService: ApiService
    ) {}

	get(id:string = null) : Observable<any>{
		let url = `/log-caixa/${id}` 
        return this.apiService.get(url)
	}
    
    getAll(filtro) : Observable<any>{
		let url = `/logs-caixa` 
        return this.apiService.get(url, filtro)
    }
}