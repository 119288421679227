import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class ReceitasService {
    constructor(
        private apiService: ApiService
    ) {}
        
    get(id): Observable<any> {
        let url : string = `/receitas/${id}`
        return this.apiService.get(url)
    }
    
    getAll(dadosFiltro?): Observable<any> {
        let url : string = `/receitas`
        return this.apiService.get(url, dadosFiltro)
    }
    
    restore(id) : Observable<any> {
        return this.apiService.get('/receitas/reativar/'+id)
    }
    
    post(form) : Observable<any> {
        return this.apiService.post('/receitas', form)
    }
    
    put(id: string, form) : Observable<any> {
        form._method='PUT'
        return this.apiService.post('/receitas/'+id, form)
    }
    
    delete(id) {
        return this.apiService.delete('/receitas/'+id)
    }
}