import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()
export class ComissoesAuxiliaresService {
	constructor(private apiService: ApiService) {}

	getComissoesAuxiliares(filtro?): Observable<any> {
		return this.apiService.post(`/comissoes-auxiliares`, filtro).pipe(
			retry(5),
			catchError(() => {
				return EMPTY
			}),
			shareReplay()
		)
	}

	getRelatorioComissoesAuxiliares(filtro?): Observable<any> {
		return this.apiService
			.post(`/relatorio-comissoes-auxiliares`, filtro)
			.pipe(
				retry(5),
				catchError(() => {
					return EMPTY
				}),
				shareReplay()
			)
	}

	postComissoesPagas(form): Observable<any> {
		return this.apiService.post('/comissoes-auxiliares-pagas', form)
	}
}
