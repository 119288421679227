import { Router } from '@angular/router'
import { FormGroup } from '@angular/forms'
import * as moment from 'moment'
import { HttpHeaders } from '@angular/common/http'

import { ClinicaService, PermissoesService } from '../../app/core/services'
import { PermissaoCadastro } from '../../app/interfaces/permissoes/permissao-cadastro.interface'
import { PermissaoEspecifica } from '../../app/interfaces/permissoes/permissao-especifica.interface'
import Swal from 'sweetalert2'

declare let $: any


export class Utils {
	static acoes: PermissaoCadastro
	
	static Toast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 3000,
		didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
		}
	})

	constructor() {}

	static dateExtenso(date) {
		const arrayDate = date.split('-')
		const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
		const mes = parseInt(arrayDate['1'])
		return arrayDate['2'] + ' de ' + meses[mes - 1] + ' de ' + arrayDate['0']
	}

	static showNotification(type, message) {
		$.notify(
			{
				icon: 'add_alert',
				message: message
			},
			{
				type: type,
				delay: 1000,
				z_index: 10000,
				placement: {
					from: 'top',
					align: 'right'
				},
				animate: {
					enter: 'animated fadeInDown',
					exit: 'animated fadeOutUp'
				}
			}
		)
	}

	static formataCPFCNPJ(cpf_cnpj) {
		let num = cpf_cnpj
		num = num.toString()
		num = num.replace(/\D/g, '')

		switch (num.length) {
			case 4:
				num = num.replace(/(\d+)(\d{3})/, '$1.$2')
				break
			case 5:
				num = num.replace(/(\d+)(\d{3})/, '$1.$2')
				break
			case 6:
				num = num.replace(/(\d+)(\d{3})/, '$1.$2')
				break
			case 7:
				num = num.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3')
				break
			case 8:
				num = num.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3')
				break
			case 9:
				num = num.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3')
				break
			case 10:
				num = num.replace(/(\d+)(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4')
				break
			case 11:
				num = num.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
				break
			case 12:
				num = num.replace(/(\d+)(\d{3})(\d{3})(\d{4})/, '$1.$2.$3/$4')
				break
			case 13:
				num = num.replace(/(\d+)(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
				break
			case 14:
				num = num.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, '$1.$2.$3/$4-$5')
				break
		}
		return num
	}

	static formataInstagram(instagram: string): string {
		if (!instagram) {
			return '';
		}

		return '@' + instagram.toLocaleLowerCase().replace(/[^a-zA-Z0-9_.]/g, '');
	}

	static isMac(): boolean {
		let bool = false;
		if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
			bool = true;
		}
		return bool;
	}

	static fillFieldsForm(data: Object, fieldsForm: any, formTarget: FormGroup) {
		fieldsForm.forEach((field) => {
			const fieldAndIndex = field.indexOf('.') != -1 ? field.split('.') : field
			if ((Array.isArray(fieldAndIndex) && data[fieldAndIndex[0]] != null) || (!Array.isArray(fieldAndIndex) && data[fieldAndIndex] != null)) {
				if (Array.isArray(fieldAndIndex)) {
					formTarget.get(fieldAndIndex[0]).patchValue(data[fieldAndIndex[0]][fieldAndIndex[1]])
				} else {
					if (Array.isArray(data[fieldAndIndex])) {
						const listMultipleSelect = []
						data[fieldAndIndex].forEach((multiple) => {
							listMultipleSelect.push(multiple['id'])
						})
						data[fieldAndIndex] = listMultipleSelect
					}
					formTarget.get(field).patchValue(data[fieldAndIndex])
				}
			}
		})
	}

	static showErrorsSubmit(response) {
		if ([undefined, null, false].indexOf(response.erros) == -1) {
			Object.keys(response.erros).forEach((item) => {
				response.erros[item].forEach((erro) => {
					this.Toast.fire({
						icon: 'error',
						title: erro
					})
				})
			})
		} else {
			this.Toast.fire({
				icon: 'error',
				title: response.resposta
			})
		}
	}

	static valida_cpf_cnpj(dado) {
		dado = dado.replace(/[^\d]+/g, '')
		if (dado.length == 14) {
			let tamanho = dado.length - 2
			let numeros = dado.substring(0, tamanho)
			const digitos = dado.substring(tamanho)
			let soma = 0
			let pos = tamanho - 7
			for (let i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--
				if (pos < 2) {
					pos = 9
				}
			}
			let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
			if (resultado != digitos.charAt(0)) {
				return false
			}
			tamanho = tamanho + 1
			numeros = dado.substring(0, tamanho)
			soma = 0
			pos = tamanho - 7
			for (let i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--
				if (pos < 2) {
					pos = 9
				}
			}
			resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
			if (resultado != digitos.charAt(1)) {
				return false
			}
			return true
		} else if (dado.length == 11) {
			let numero = 0
			let caracter = ''
			const numeros = '0123456789'
			let j = 10
			let somatorio = 0
			let resto = 0
			let digito1 = 0
			let digito2 = 0
			let cpfAux = ''
			cpfAux = dado.substring(0, 9)
			for (let i = 0; i < 9; i++) {
				caracter = cpfAux.charAt(i)
				if (numeros.search(caracter) == -1) {
					return false
				}
				numero = Number(caracter)
				somatorio = somatorio + numero * j
				j--
			}
			resto = somatorio % 11
			digito1 = 11 - resto
			if (digito1 > 9) {
				digito1 = 0
			}
			j = 11
			somatorio = 0
			cpfAux = cpfAux + digito1
			for (let i = 0; i < 10; i++) {
				caracter = cpfAux.charAt(i)
				numero = Number(caracter)
				somatorio = somatorio + numero * j
				j--
			}
			resto = somatorio % 11
			digito2 = 11 - resto
			if (digito2 > 9) {
				digito2 = 0
			}
			cpfAux = cpfAux + digito2
			if (dado != cpfAux) {
				return false
			} else {
				return true
			}
		} else {
			return false
		}
	}

	static dataURItoBlob(dataURI) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		let byteString
		if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
		else byteString = unescape(dataURI.split(',')[1])

		// separate out the mime component
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

		// write the bytes of the string to a typed array
		const ia = new Uint8Array(byteString.length)
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}

		return new Blob([ia], { type: mimeString })
	}

	static calculaDiferenca(horarioMaior, horarioMenor) {
		horarioMaior = moment(moment().format('YYYY-MM-DD') + ` ${horarioMaior}`)
		horarioMenor = moment(moment().format('YYYY-MM-DD') + ` ${horarioMenor}`)
		let horas = horarioMaior.diff(horarioMenor, 'hours')
		let minutos = horarioMaior.diff(horarioMenor, 'minutes')
		let segundos = horarioMaior.diff(horarioMenor, 'seconds')

		if (minutos > 59) minutos = minutos - horas * 60

		if (segundos > 59) segundos = segundos - minutos * 60

		horas = horas < 10 ? `0${horas}` : horas
		minutos = minutos < 10 ? `0${minutos}` : minutos
		segundos = segundos < 10 ? `0${segundos}` : segundos

		return `${horas}:${minutos}:${segundos}`
	}

	/**
	 * Retorna a string sem acentuação e em lower case
	 * @param  {String} stringComAcento [string que contem os acentos]
	 * @return {String}                 [string sem acentos]
	 */
	static removerAcentos(newStringComAcento) {
		let string = newStringComAcento
		const mapaAcentosHex = {
			a: /[\xE0-\xE6]/g,
			A: /[\xC0-\xC6]/g,
			e: /[\xE8-\xEB]/g,
			E: /[\xC8-\xCB]/g,
			i: /[\xEC-\xEF]/g,
			I: /[\xCC-\xCF]/g,
			o: /[\xF2-\xF6]/g,
			O: /[\xD2-\xD6]/g,
			u: /[\xF9-\xFC]/g,
			U: /[\xD9-\xDC]/g,
			c: /\xE7/g,
			C: /\xC7/g,
			n: /\xF1/g,
			N: /\xD1/g
		}

		for (const letra in mapaAcentosHex) {
			const expressaoRegular = mapaAcentosHex[letra]
			string = string.replace(expressaoRegular, letra)
		}

		return string.toLowerCase()
	}

	static async getDadosClinica(ClinicaService: ClinicaService) {
		const response = await ClinicaService.get().toPromise();

		if (response.success == 1) {
			return response.data;
		} else {
			return null;
		}
	}

	static async verificarPermissaoCadastro(router: Router, PermissoesCadastros: PermissoesService) {
		
		const usuario = JSON.parse(localStorage.getItem('usuario'))
		if (usuario) {
			const rota = router.url.replace('/', '')
			const slug_base = rota.indexOf('/') !== -1 ? rota.split('/')[0] : rota
			const { data: permissoesUsuario } = await PermissoesCadastros.buscarApenasPermissoesCadastros(usuario.id).toPromise()
			const permissao = permissoesUsuario.find((permissao) => permissao.slug === slug_base)
			this.acoes = {
				ler: usuario.admin || permissao.ler ? true : false,
				adicionar: usuario.admin || permissao.adicionar ? true : false,
				editar: usuario.admin || permissao.editar ? true : false,
				excluir: usuario.admin || permissao.excluir ? true : false
			}
			return this.acoes			
		}
	}

	static async verificarPermissaoEspecifica(PermissoesCadastros: PermissoesService, modulo: string) {
		const usuario = JSON.parse(localStorage.getItem('usuario'))
		if (usuario) {
			const { data: permissoes } = await PermissoesCadastros.buscarApenasPermissoesEspecificas(usuario.id, modulo).toPromise()
			const acoes: Array<PermissaoEspecifica> = []
			permissoes.map((permissao) => {
				if (usuario.admin) permissao.especifica_autorizada = true
	
				acoes[permissao.slug] = permissao
			})
			return acoes
		}
	}

	static async verificarPermissaoExtra(PermissoesCadastros: PermissoesService, slug: string) {
		const { id: idUsuario } = JSON.parse(localStorage.getItem('usuario'))
		const { data: permissaoExtra } = await PermissoesCadastros.getPermissao(idUsuario, slug).toPromise()
		let acoes = null
		if (permissaoExtra.especifica) {
			acoes = {
				especifica_autorizada: permissaoExtra.especifica_autorizada
			}
		} else {
			acoes = {
				ler: permissaoExtra.ler,
				adicionar: permissaoExtra.adicionar,
				editar: permissaoExtra.editar,
				excluir: permissaoExtra.excluir
			}
		}
		return acoes
	}

	static buscarTokenAutenticacao() {
		const access_token = localStorage.getItem('access_token')
		const headers = new HttpHeaders({
			Authorization: `Bearer ${access_token}`
		})
		return headers
	}

	static logout(router: Router) {
		localStorage.removeItem('access_token')
		localStorage.removeItem('usuario')
		router.navigate(['/'])
	}
}
