import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class AvisoAgendaService {
	constructor(private apiService: ApiService) {}

	post(form): Observable<any> {
		return this.apiService.post('/aviso-agenda', form)
	}

	getAll(idAviso): Observable<any> {
		const url = `/aviso-agenda`
		return this.apiService.get(url, idAviso)
	}

	get(id: string): Observable<any> {
		const url = `/aviso-agenda/${id}`
		return this.apiService.get(url)
	}

	put(id: string, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/aviso-agenda/' + id, form)
	}

	delete(id) {
		return this.apiService.delete('/aviso-agenda/' + id)
	}

	VerificaAvisoAgendaUpdateCliente(id) {
		return this.apiService.post(
			'/verifica-update-cliente-aviso-agenda/' + id
		)
	}

	cadastroEmailSmsAgendaPre(id: string): Observable<any> {
		const url = `/cadastro-email-sms-agenda-pre/${id}`
		return this.apiService.post(url)
	}

	cadastroEmailSmsAgendaPos(id: string): Observable<any> {
		const url = `/cadastro-email-sms-agenda-pos/${id}`
		return this.apiService.post(url)
	}
}
