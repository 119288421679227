import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class AtendimentoService {
	constructor(private apiService: ApiService) {}

	getAllAtendidos(dadosFiltro?): Observable<any> {
		const url = `/atendimentos-tratamentos-atendidos`
		return this.apiService.post(url, dadosFiltro)
	}

	getAllAtendimentosAtendidos(dadosFiltro?): Observable<any> {
		const url = `/atendimentos-atendidos`
		return this.apiService.post(url, dadosFiltro)
	}

	adicionarTratamentoAgendamento(
		id_agendamento: number,
		form
	): Observable<any> {
		return this.apiService.post(
			`/atendimento-adicionar-tratamento/${id_agendamento}`,
			form
		)
	}

	excluirTratamentoAgendamento(
		id_agendamento: number,
		id_tratamento: number
	): Observable<any> {
		return this.apiService.delete(
			`/atendimento-excluir-tratamento/${id_agendamento}/${id_tratamento}`
		)
	}

	editarTratamentoAgendamento(form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post(
			`/atendimento-editar-tratamento/${form.id_agendamento}/${form.id}`,
			form
		)
	}

	adicionarAtendimento(atendimento): Observable<any> {
		return this.apiService.post(`/adicionar-atendimento`, atendimento)
	}

	adicionarAtendimentoTratamentoInsumo(atendimento): Observable<any> {
		return this.apiService.post(`/atendimentos-tratamentos-insumos`, atendimento)
	}

	deleterAtendimento(id) {
		return this.apiService.delete(`/deletar-atendimento/${id}`)
	}

	deleterAtendimentoRetroativo(id) {
		return this.apiService.post(`/deletar-atendimento-retroativo/${id}`)
	}

	postAtendimentoTratamentoRetroativo(form): Observable<any> {
		const url = '/atendimentos-tratamentos-retroativos'
		return this.apiService.post(url, form)
	}
}
