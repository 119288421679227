import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpModule } from '@angular/http'
import { HttpClientModule } from '@angular/common/http'
import { RouterModule } from '@angular/router'
import { CoreModule } from './core/core.module'

import { AppRoutingModule } from './app.routing'
import { ComponentsModule } from './components/components.module'

import { AppComponent } from './app.component'

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component'
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading'
import { LoginComponent } from './security/login/login.component'
import { LoginService } from './core/services/login.service'
import { NgxViacepModule } from '@brunoc/ngx-viacep'
import { MessageService } from './core/services';

@NgModule({
	imports: [
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpModule,
		HttpClientModule,
		ComponentsModule,
		RouterModule,
		AppRoutingModule,
		NgxViacepModule,
		NgxLoadingModule.forRoot({
			animationType: ngxLoadingAnimationTypes.wanderingCubes,
			backdropBackgroundColour: 'rgba(0,0,0,0.1)',
			backdropBorderRadius: '4px',
			primaryColour: '#ffffff',
			secondaryColour: '#ffffff',
			tertiaryColour: '#ffffff',
			fullScreenBackdrop: true
		}),
		CoreModule
	],
	declarations: [AppComponent, AdminLayoutComponent, LoginComponent],
	providers: [LoginService, MessageService],
	bootstrap: [AppComponent]
})
export class AppModule {}
