import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'

@Injectable()
export class PermissoesService {
    constructor(private apiService: ApiService) {
        
    }
    
    getPermissoes(id?): Observable<any> {
        let url : string = id ? `/permissoes-cadastros/agrupados/editar/${id}` : `/permissoes-cadastros/agrupados/criar`
        return this.apiService.get(url)
    }

    buscarApenasPermissoesCadastros(idUsuario: number, modulo?: string): Observable<any> {
        let url : string = `/permissoes-cadastros/${idUsuario}`
        return this.apiService.get(url, new HttpParams().set('modulo', modulo))
    }

    buscarApenasPermissoesEspecificas(idUsuario: number, modulo: string): Observable<any> {
        let url : string = `/permissoes-especificas/${idUsuario}`
        return this.apiService.get(url, new HttpParams().set('modulo', modulo))
    }

    getPermissao(idUsuario, slug: string){
        let url : string = `/permissoes-cadastros-slug/${idUsuario}/${slug}`
        return this.apiService.get(url)
    }
    
}