import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class TipoAnamneseRespostaService {
    constructor(
        private apiService: ApiService
    ) {}

    getAll(dadosFiltro?): Observable<any> {
        let url : string = `/tipo-anamnese-resposta`
        return this.apiService.get(url, dadosFiltro)
    }
}