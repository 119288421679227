import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class FuncionariosService {
	constructor(private apiService: ApiService) {}

	post(form): Observable<any> {
		return this.apiService.post('/funcionarios', form)
	}

	getAll(filtro?): Observable<any> {
		const url = `/funcionarios`
		return this.apiService.get(url, filtro)
	}

	getProfissionaisAgenda(): Observable<any> {
		const url = `/profissionais-agenda`
		return this.apiService.get(url)
	}

	getProfissionaisAgendaSelect(): Observable<any> {
		const url = `/profissionais-agenda-select`
		return this.apiService.get(url)
	}

	getProfissionaisFiltroTratamento(filtro): Observable<any> {
		const url = `/profissionais-filter-tratamento`
		return this.apiService.get(url, filtro)
	}

	get(id: string): Observable<any> {
		const url = `/funcionarios/${id}`
		return this.apiService.get(url)
	}

	getComissionadoVenda(idVenda: string): Observable<any> {
		return this.apiService.get(`/comissionado-venda/${idVenda}`)
	}

	put(id: string, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/funcionarios/' + id, form)
	}

	delete(id) {
		return this.apiService.delete('/funcionarios/' + id)
	}

	restore(id): Observable<any> {
		return this.apiService.get('/funcionarios/reativar/' + id)
	}

	uploadUsuarioImagem(id, request): Observable<any> {
		return this.apiService.post('/funcionarios/upload/' + id, request)
	}

	uploadFoto(id, request): Observable<any> {
		return this.apiService.post('/funcionarios/upload-foto/' + id, request)
	}

	deleteFoto(id): Observable<any> {
		const url = `/funcionarios/delete-foto/${id}`
		return this.apiService.get(url)
	}
}
