import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class EstadosService {
    constructor(private apiService: ApiService) {

    }

    getEstados(): Observable<any> {
        let url : string = `/estados`
        return this.apiService.get(url)
    }

    getEstadoByID(id: string){
        let url : string = `/estados/${id}`
        return this.apiService.get(url)
    }

}