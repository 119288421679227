import { Component, OnInit, ElementRef} from '@angular/core'
import { ROUTES } from '../sidebar/sidebar.component'
import { Location } from '@angular/common'
import { Router } from '@angular/router'
import {
	AgendaService,
	VendasService,
	ReceituariosService,
	MessageService,
	PermissoesService,
	OportunidadesService,
	ClientesService,
	OrcamentoNotificacoesService,
	ConfiguracoesAgendaService,
	OrcamentosService
} from '../../core/services'
import { BehaviorSubject, Subscription } from 'rxjs'
import { Utils } from '../../../assets/ts/utils'
import { PermissaoCadastro } from 'app/interfaces/permissoes/permissao-cadastro.interface'
import * as moment from 'moment'

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
	subscriptionAtualizarNotificacoes: Subscription
	subscriptionScroll: Subscription

	private listTitles: any[]
	location: Location
	mobile_menu_visible: any = 0
	private toggleButton: any
	private sidebarVisible: boolean
	usuario = JSON.parse(localStorage.getItem('usuario'))

	atendimentos_pendentes: any = []
	oportunidade_pendentes: any = []
	vendas_pendentes: any = []
	receitas: any = []
	listaEsperaTotal: any = []
	titlee: any
	aniversariantes: any = []
	// orcamento_notificacoes: any = []
	total_notificacoes: any = []
	navBarFixo: number
	navBarMenor: boolean = false;

	acoes: PermissaoCadastro
	private buscaPermissoes = new BehaviorSubject<boolean>(false)
	public buscaPermissoesRealizada$ = this.buscaPermissoes.asObservable()	
	orcamentos: any = []
	alerta: any
	orcamentosVencidos: any

	public timeElapsed = Date.now();
	public today = new Date(this.timeElapsed);
	public formattedDate = this.today.toISOString().split('T')[0];
	configuracoes: any
	notificacaoOrcamento: any
	orcamentosAbertos: any
	

	constructor(
		location: Location,
		private router: Router,
		private element: ElementRef,
		private VendasService: VendasService,
		private AgendaService: AgendaService,
		private MessageService: MessageService,
		private ClientesService: ClientesService,
		private OrcamentosService: OrcamentosService,
		private PermissoesService: PermissoesService,
		private ReceituarioService: ReceituariosService,
		private OportunidadesService: OportunidadesService,
		private ConfiguracoesAgendaService: ConfiguracoesAgendaService,
		private OrcamentoNotificacoesService: OrcamentoNotificacoesService,
		) {
		this.subscriptionAtualizarNotificacoes = this.MessageService.getAtualizarNotificacoesSistema().subscribe((message) => {
			this.getNotificacoes()
		})
		this.location = location

		this.subscriptionScroll = this.MessageService.getScrollToNavbar().subscribe(async (message) => {
			const navbar1: HTMLElement = this.element.nativeElement
			const topbar = navbar1.getElementsByClassName('top-bar')[0]
				if (message > 200){
					topbar.classList.add('menor')
					topbar.classList.remove('maior')
					if (message && this.titlee != "/agenda") {
						topbar.classList.add('boxShadow')
						topbar.classList.remove('semBoxShadow')
					} else {
						topbar.classList.add('semBoxShadow')
						topbar.classList.remove('boxShadow')
					}
				} else {					
					topbar.classList.add('maior')
					topbar.classList.remove('menor')
					topbar.classList.add('boxShadow')
					topbar.classList.remove('semBoxShadow')
				}
		})
	}

	async ngOnInit() {
		this.sidebarVisible = false
		this.listTitles = ROUTES.filter((listTitle) => listTitle)
		const navbar: HTMLElement = this.element.nativeElement

		const response = await this.ConfiguracoesAgendaService.get().toPromise();
		this.alerta = response.data.alerta_notificacao;	
		this.notificacaoOrcamento = response.data.notificacao_orcamento


		this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0]
		this.router.events.subscribe((event) => {
			this.sidebarClose()
			const $layer: any = document.getElementsByClassName('close-layer')[0]
			if ($layer) {
				$layer.remove()
				this.mobile_menu_visible = 0
			}
		})
		this.MessageService.sendAtualizarNotificacoesSistema('teste de service')	
	}
	
	

	async ngAfterViewInit(): Promise<void> {
		await this.buscarPermissoes()
	}

	async buscarPermissoes(): Promise<void> {
		this.acoes = await Utils.verificarPermissaoCadastro(this.router, this.PermissoesService)
		this.buscaPermissoes.next(true)
	}

	ngOnDestroy() {
		this.subscriptionAtualizarNotificacoes.unsubscribe()
	}

	

	sidebarOpen() {
		const toggleButton = this.toggleButton
		const body = document.getElementsByTagName('body')[0]
		setTimeout(function () {
			toggleButton.classList.add('toggled')
		}, 500)

		body.classList.add('nav-open')

		this.sidebarVisible = true
	}
	sidebarClose() {
		const body = document.getElementsByTagName('body')[0]
		this.toggleButton.classList.remove('toggled')
		this.sidebarVisible = false
		body.classList.remove('nav-open')
	}
	sidebarToggle() {
		// const toggleButton = this.toggleButton
		// const body = document.getElementsByTagName('body')[0]
		const $toggle = document.getElementsByClassName('navbar-toggler')[0]

		if (this.sidebarVisible === false) {
			this.sidebarOpen()
		} else {
			this.sidebarClose()
		}
		const body = document.getElementsByTagName('body')[0]

		if (this.mobile_menu_visible == 1) {
			// $('html').removeClass('nav-open')
			body.classList.remove('nav-open')
			if ($layer) {
				$layer.remove()
			}
			setTimeout(function () {
				$toggle.classList.remove('toggled')
			}, 400)

			this.mobile_menu_visible = 0
		} else {
			setTimeout(function () {
				$toggle.classList.add('toggled')
			}, 430)

			var $layer = document.createElement('div')
			$layer.setAttribute('class', 'close-layer')

			if (body.querySelectorAll('.main-panel')) {
				document.getElementsByClassName('main-panel')[0].appendChild($layer)
			} else if (body.classList.contains('off-canvas-sidebar')) {
				document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer)
			}

			setTimeout(function () {
				$layer.classList.add('visible')
			}, 100)

			$layer.onclick = function () {
				//asign a function
				body.classList.remove('nav-open')
				this.mobile_menu_visible = 0
				$layer.classList.remove('visible')
				setTimeout(function () {
					$layer.remove()
					$toggle.classList.remove('toggled')
				}, 400)
			}.bind(this)

			body.classList.add('nav-open')
			this.mobile_menu_visible = 1
		}
	}

	getTitle() {
		this.titlee = this.location.prepareExternalUrl(this.location.path())		
		if (this.titlee.charAt(0) === '#') {
			this.titlee = this.titlee.slice(1)
		}
			for (let item = 0; item < this.listTitles.length; item++) {
				if (this.listTitles[item].path === this.titlee) {					
					return this.listTitles[item].title
				}	
			}
		return 'Dashboard'
	}

	async getNotificacoes() {
		// const orcamento_notificacoes = await this.OrcamentoNotificacoesService.getAll().toPromise()
		const hoje = moment().format('YYYY-MM-DD')
		const oportunidade_pendentes_response = await this.OportunidadesService.getNotificacoes().toPromise();
		const vendas_pendentes_response = await this.VendasService.getPendentes().toPromise();
		const atendimentos_response = await this.AgendaService.getAtendimentosPendentesNotificacao().toPromise();
		const aniversariantes_response = await this.ClientesService.getAniversario({
			data_inicio: [hoje],
			data_fim: [hoje]
		}).toPromise();
		const receituario_response = await this.ReceituarioService.getAll({
			impresso: 0
		}).toPromise();
		const orcamentos = await this.OrcamentosService.getAllNotificacao({status: 'Em Aberto'}).toPromise()
		const orcamentosVencidos = await this.OrcamentosService.getAllNotificacao({status: 'Em Aberto', data_venda_fim: this.formattedDate }).toPromise()



		let dadosFiltro = {data_inicio: hoje}
		const listaEsperaResponse =  await this.AgendaService.getListaEspera(dadosFiltro).toPromise()
		
		if (listaEsperaResponse.success == 1){
			
			this.listaEsperaTotal = listaEsperaResponse.data ? listaEsperaResponse.data.length : (listaEsperaResponse.data.data ? listaEsperaResponse.data.data.length : 0)
		} else {
			this.listaEsperaTotal = 0
		}
		
		

		// this.orcamento_notificacoes = orcamento_notificacoes.data ? orcamento_notificacoes.data : []
		this.vendas_pendentes = vendas_pendentes_response.data;
		this.oportunidade_pendentes = oportunidade_pendentes_response ? oportunidade_pendentes_response.data : [];
		this.atendimentos_pendentes = atendimentos_response.data;
		this.receitas = receituario_response.data;
		this.aniversariantes = aniversariantes_response.data;
		
		this.orcamentosAbertos = orcamentos.data;
		this.orcamentosVencidos = orcamentosVencidos.data;

		this.total_notificacoes = this.atendimentos_pendentes.length + this.vendas_pendentes.length + this.receitas.length;
	}
	
	logout() {
		localStorage.removeItem('dadosFiltroVendasPendentes')
		localStorage.removeItem('dadosFiltroCaixaContasPagar')
		localStorage.removeItem('dadosFiltroHistoricoCaixa')
		localStorage.removeItem('dadosFiltroHistoricoVendasReceber')
		localStorage.removeItem('dadosFiltroCliente')
		localStorage.removeItem('dadosFiltroOrcamento')
		localStorage.removeItem('dadosFiltroTratamentos')
		localStorage.removeItem('dadosFiltroVenda')

		localStorage.removeItem('access_token')
		localStorage.removeItem('usuario')
		localStorage.clear()
		this.router.navigate(['/'])
	}
}
