import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class ConfiguracoesVendaService {
    constructor(private apiService: ApiService) {}
    
    getVendaProdutos(): Observable<any> {
        let url : string = `/configuracoes-venda-venda-produtos`
        return this.apiService.get(url)
    }
    
    putVendaProdutos(form: any): Observable<any> {
        form._method = 'PUT'
        return this.apiService.post(`/editar-configuracoes-venda-produtos`, form)
    }
	
    getConversaoZero(): Observable<any> {
        let url : string = `/configuracoes-venda-conversao-zero`
        return this.apiService.get(url)
    }
    
	putConversaoZero(habilitado: boolean): Observable<any> {
        let form = {habilitado: habilitado, _method: 'PUT'}
        return this.apiService.post(`/editar-configuracoes-conversao-zero`, form)
    }

    getConvenioPadrao(): Observable<any> {
        let url : string = `/configuracoes-venda-convenio`
        return this.apiService.get(url)
    }

    putConvenioPadrao(habilitado: boolean): Observable<any> {
        let form = {habilitado: habilitado, _method: 'PUT'}
        return this.apiService.post(`/editar-configuracoes-convenio-padrao`, form)
	}
	
	getDataVendaAgendamento(): Observable<any> {
        let url : string = `/configuracoes-data-venda-agendamento`
        return this.apiService.get(url)
    }

    putDataVendaAgendamento(habilitado: boolean): Observable<any> {
        let form = {habilitado: habilitado, _method: 'PUT'}
        return this.apiService.post(`/editar-configuracoes-data-venda-agendamento`, form)
    }
}