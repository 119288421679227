import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'

@Injectable()
export class ModalService {
	private messageCloseCadastrarAgendamentoInAgenda = new Subject<boolean>()
	currentCloseCadastrarAgendamentoInAgenda = this.messageCloseCadastrarAgendamentoInAgenda.asObservable()

	private messageCloseAddTratamentosInCadastroAgendamentoTratamento = new Subject<
		boolean
	>()
	currentCloseAddTratamentosInCadastroAgendamentoTratamento = this.messageCloseAddTratamentosInCadastroAgendamentoTratamento.asObservable()

	private messageCloseAddTratamentosInAtendimento = new Subject<boolean>()
	currentCloseAddTratamentosInAtendimento = this.messageCloseAddTratamentosInAtendimento.asObservable()

	private messageCloseAddInsumoInTratamentosCustosInsumos = new Subject<
		boolean
	>()
	currentCloseAddInsumoInTratamentosCustosInsumos = this.messageCloseAddInsumoInTratamentosCustosInsumos.asObservable()

	private messageCloseAddFormaRecebimentoToCadastroVendaFormasRecebimento = new Subject<
		boolean
	>()
	currentCloseAddFormaRecebimentoToCadastroVendaFormasRecebimento = this.messageCloseAddFormaRecebimentoToCadastroVendaFormasRecebimento.asObservable()

	private messageCloseAddFormaPagamentoToCadastroCompraFormasPagamento = new Subject<
		boolean
	>()
	currentCloseAddFormaPagamentoToCadastroCompraFormasPagamento = this.messageCloseAddFormaPagamentoToCadastroCompraFormasPagamento.asObservable()
	private messageCloseDestinadorInFechamentoCaixaDestinaveisDestinosCompensacao = new Subject<
		boolean
	>()
	currentCloseDestinadorInFechamentoCaixaDestinaveisDestinosCompensacao = this.messageCloseDestinadorInFechamentoCaixaDestinaveisDestinosCompensacao.asObservable()

	private messageCloseDestinadorInFechamentoCaixaDestinaveisContasBancarias = new Subject<
		boolean
	>()
	currentCloseDestinadorInFechamentoCaixaDestinaveisContasBancarias = this.messageCloseDestinadorInFechamentoCaixaDestinaveisContasBancarias.asObservable()

	private messageCloseAdicionarMovimentoContaBancaria = new Subject<boolean>()
	currentCloseAdicionarMovimentoContaBancaria = this.messageCloseAdicionarMovimentoContaBancaria.asObservable()

	private messageClosePermissoesModalToCadastroFuncionarios = new Subject<
		boolean
	>()
	currentClosePermissoesModalToCadastroFuncionarios = this.messageClosePermissoesModalToCadastroFuncionarios.asObservable()

	private messageCloseAddInTratamento = new Subject<boolean>()
	currentCloseAddInTratamento = this.messageCloseAddInTratamento.asObservable()

	private messageCloseConversaoCredito = new Subject<boolean>()
	currentCloseConversaoCredito = this.messageCloseConversaoCredito.asObservable()

	private messageCloseAbaterValorVendaCarteiraDigital = new Subject<boolean>()

	private messageCloseAddMovimentoSaida = new Subject<boolean>()

	private messageCloseAddMovimentoEntrada = new Subject<boolean>()

	private messageCloseConfirmarImpressaoTermoConfissaoDivida = new Subject<
		boolean
	>()

	sendCloseCadastrarAgendamentoInAgenda(message: boolean) {
		this.messageCloseCadastrarAgendamentoInAgenda.next(message)
	}

	getCloseCadastrarAgendamentoInAgenda(): Observable<any> {
		return this.messageCloseCadastrarAgendamentoInAgenda.asObservable()
	}

	sendCloseAddTratamentosInCadastroAgendamentoTratamentos(message: boolean) {
		this.messageCloseAddTratamentosInCadastroAgendamentoTratamento.next(
			message
		)
	}

	getCloseAddTratamentosInCadastroAgendamentoTratamentos(): Observable<any> {
		return this.messageCloseAddTratamentosInCadastroAgendamentoTratamento.asObservable()
	}

	sendCloseAddTratamentosInAtendimento(message: boolean) {
		this.messageCloseAddTratamentosInAtendimento.next(message)
	}

	getCloseAddTratamentosInAtendimento(): Observable<any> {
		return this.messageCloseAddTratamentosInAtendimento.asObservable()
	}

	sendCloseAddInsumoInTratamentosCustosInsumos(message: boolean) {
		this.messageCloseAddTratamentosInAtendimento.next(message)
	}

	getCloseAddInsumoInTratamentosCustosInsumos(): Observable<any> {
		return this.messageCloseAddTratamentosInAtendimento.asObservable()
	}

	sendCloseAddFormaRecebimentoToCadastroVendaFormasRecebimento(
		message: boolean
	) {
		this.messageCloseAddFormaRecebimentoToCadastroVendaFormasRecebimento.next(
			message
		)
	}

	getCloseAddFormaRecebimentoToCadastroVendaFormasRecebimento(): Observable<
		any
	> {
		return this.messageCloseAddFormaRecebimentoToCadastroVendaFormasRecebimento.asObservable()
	}

	sendCloseAddFormaPagamentoToCadastroCompraFormasPagamento(
		message: boolean
	) {
		this.messageCloseAddFormaPagamentoToCadastroCompraFormasPagamento.next(
			message
		)
	}

	getCloseAddFormaPagamentoToCadastroCompraFormasPagamento(): Observable<
		any
	> {
		return this.messageCloseAddFormaPagamentoToCadastroCompraFormasPagamento.asObservable()
	}

	sendCloseDestinadorInFechamentoCaixaDestinaveisDestinosCompensacao(
		message: boolean
	) {
		this.messageCloseDestinadorInFechamentoCaixaDestinaveisDestinosCompensacao.next(
			message
		)
	}

	getCloseDestinadorInFechamentoCaixaDestinaveisDestinosCompensacao(): Observable<
		any
	> {
		return this.messageCloseDestinadorInFechamentoCaixaDestinaveisDestinosCompensacao.asObservable()
	}

	sendCloseDestinadorInFechamentoCaixaDestinaveisContasBancarias(
		message: boolean
	) {
		this.messageCloseDestinadorInFechamentoCaixaDestinaveisContasBancarias.next(
			message
		)
	}

	getCloseDestinadorInFechamentoCaixaDestinaveisContasBancarias(): Observable<
		any
	> {
		return this.messageCloseDestinadorInFechamentoCaixaDestinaveisContasBancarias.asObservable()
	}

	sendCloseAdicionarMovimentoContaBancaria(message: boolean) {
		this.messageCloseAdicionarMovimentoContaBancaria.next(message)
	}

	getCloseAdicionarMovimentoContaBancaria(): Observable<any> {
		return this.messageCloseAdicionarMovimentoContaBancaria.asObservable()
	}

	sendClosePermissoesModalToCadastroFuncionarios(message: boolean) {
		this.messageClosePermissoesModalToCadastroFuncionarios.next(message)
	}

	getClosePermissoesModalToCadastroFuncionarios(): Observable<any> {
		return this.messageClosePermissoesModalToCadastroFuncionarios.asObservable()
	}

	sendCloseAddInTratamento(message) {
		this.messageCloseAddInTratamento.next(message)
	}

	getCloseAddInTratamento(): Observable<any> {
		return this.messageCloseAddInTratamento.asObservable()
	}

	sendCloseConversaoCredito(message) {
		this.messageCloseConversaoCredito.next(message)
	}

	getCloseConversaoCredito(): Observable<any> {
		return this.messageCloseConversaoCredito.asObservable()
	}

	sendCloseAbaterValorVendaCarteiraDigital(message) {
		this.messageCloseAbaterValorVendaCarteiraDigital.next(message)
	}

	getCloseAbaterValorVendaCarteiraDigital(): Observable<any> {
		return this.messageCloseAbaterValorVendaCarteiraDigital.asObservable()
	}

	sendCloseAddMovimentoSaida(message: boolean): void {
		this.messageCloseAddMovimentoSaida.next(message)
	}

	getCloseAddMovimentoSaida(): Observable<boolean> {
		return this.messageCloseAddMovimentoSaida.asObservable()
	}

	sendCloseAddMovimentoEntrada(message: boolean): void {
		this.messageCloseAddMovimentoEntrada.next(message)
	}

	getCloseAddMovimentoEntrada(): Observable<boolean> {
		return this.messageCloseAddMovimentoEntrada.asObservable()
	}

	sendCloseConfirmarImpressaoTermoConfissaoDivida(message: boolean): void {
		this.messageCloseConfirmarImpressaoTermoConfissaoDivida.next(message)
	}

	getCloseConfirmarImpressaoTermoConfissaoDivida(): Observable<boolean> {
		return this.messageCloseConfirmarImpressaoTermoConfissaoDivida.asObservable()
	}
}
