import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()
export class CategoriasFinanceirasService {
    constructor(
        private apiService: ApiService
    ) {}
        
    get(id): Observable<any> {
        let url : string = `/categorias-financeiras/${id}`
        return this.apiService.get(url)
    }
    
    getAll(dadosFiltro?): Observable<any> {
        let url : string = `/categorias-financeiras`
        return this.apiService.get(url, dadosFiltro)
    }
    
    restore(id) : Observable<any> {
        return this.apiService.get('/categorias-financeiras/reativar/'+id)
    }
    
    post(form) : Observable<any> {
        return this.apiService.post('/categorias-financeiras', form)
    }
    
    put(id: string, form) : Observable<any> {
        form._method='PUT'
        return this.apiService.post('/categorias-financeiras/'+id, form)
    }
    
    delete(id) {
        return this.apiService.delete('/categorias-financeiras/'+id)
    }
}