import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class OrcamentosService {
	constructor(private apiService: ApiService) {}

	get(id): Observable<any> {
		const url = `/orcamento/${id}`
		return this.apiService.get(url)
	}

	getAll(filtro?): Observable<any> {
		const url = `/orcamento`
		return this.apiService.get(url, filtro)
	}

	getAllFilter(filtro?): Observable<any> {
		const url = `/orcamento-filter`
		return this.apiService.get(url, filtro)
	}

	getAllNotificacao(filtro?): Observable<any> {
		const url = `/orcamento-notificacao`
		return this.apiService.get(url, filtro)
	}

	post(form): Observable<any> {
		return this.apiService.post('/orcamento', form)
	}

	put(id: string, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/orcamento/' + id, form)
	}

	EmailOrcamento(id: string, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/enviar-email-orcamento/' + id, form)
	}

	restore(id: string): Observable<any> {
		return this.apiService.get('/orcamento/reativar/' + id)
	}
	
	delete(id): Observable<any> {
		return this.apiService.delete('/orcamento/' + id)
	}
	
	recusar(id: string, id_motivo_desistencia): Observable<any> {
		return this.apiService.get('/orcamento/recusar/' + id + '/' + id_motivo_desistencia)
	}
	// sendEmailOrcamento(id: number): Observable<object> {
	// 	return this.apiService.get('/email-orcamento/' + id)
	// }

	editarTratamentoOrcamento(form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post(`/orcamento-editar-tratamento/${form.id_orcamento}/${form.id}`, form)
	}
	getRelatorio(filtro): Observable<any> {
		return this.apiService.post('/relatorio-orcamentos', filtro)
	}

	getMotivoDesistencia(): Observable<any> {
		const url = `/motivo-desistencia`
		return this.apiService.get(url)
	}
	editMotivoDesistencia(id): Observable<any> {
		const url = `/motivo-desistencia/${id}`
		return this.apiService.get(url)
	}
	postMotivoDesistencia(form): Observable<any> {
		return this.apiService.post('/motivo-desistencia', form)
	}
	putMotivoDesistencia(id: string, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/motivo-desistencia/' + id, form)
	}
	deleteMotivoDesistencia(id): Observable<any> {
		return this.apiService.delete('/motivo-desistencia/' + id)
	}
	
}
