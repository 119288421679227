import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { ApiService } from './api.service';
import { retry, catchError, shareReplay } from 'rxjs/operators';

@Injectable()

export class AtendimentoReceituariosService {

	constructor(
		private apiService: ApiService
	) {}

    get(id): Observable<any> {
        let url : string = `/atendimento-receituarios/${id}`
        return this.apiService.get(url).pipe(
            retry(5),
            catchError(() => {
                return EMPTY
            }),
            shareReplay()
        )
    }
    
    getAll(dadosFiltro?): Observable<any> {
        let url : string = `/atendimento-receituarios`
        return this.apiService.get(url, dadosFiltro).pipe(
            retry(5),
            catchError(() => {
                return EMPTY
            }),
            shareReplay()
        )
    }  

    defineImpresso(id) : Observable<any> {
        return this.apiService.get(`/atendimento-receituarios/defineImpresso/${id}`)
    }
    
    post(form) : Observable<any> {
        return this.apiService.post('/atendimento-receituarios', form)
    }
    
    put(id: string, form) : Observable<any> {
        form._method='PUT'
        return this.apiService.post('/atendimento-receituarios/'+id, form)
    }
    
    delete(id) {
        return this.apiService.delete('/atendimento-receituarios/'+id)
    }
}
