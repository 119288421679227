import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class FechamentoCaixaService {
	constructor(private apiService: ApiService) {}

	getEntradasDiarias(filtros): Observable<any> {
		return this.apiService.get(
			`/fechamento-caixa-entradas-diarias`,
			filtros
		)
	}

	getEntradasFuturas(filtros): Observable<any> {
		return this.apiService.get(
			`/fechamento-caixa-entradas-futuras`,
			filtros
		)
	}

	getVendas(filtros): Observable<any> {
		return this.apiService.get(`/fechamento-caixa-vendas`, filtros)
	}

	// getCompras(filtros) : Observable<any> {
	//     return this.apiService.get(`/fechamento-caixa-compras`, filtros)
	// }

	getMovimentosAvulsos(filtros): Observable<any> {
		return this.apiService.get(
			`/fechamento-caixa-movimentos-avulsos`,
			filtros
		)
	}

	getEntradasDestinaveisDestinosCompensacao(filtros): Observable<any> {
		return this.apiService.get(
			`/fechamento-caixa-destinaveis-destinos-compensacao`,
			filtros
		)
	}

	getEntradasDestinaveisContasBancarias(filtros): Observable<any> {
		return this.apiService.get(
			`/fechamento-caixa-destinaveis-contas-bancarias`,
			filtros
		)
	}

	getMovimentosDestinaveis(filtros?) {
		return this.apiService.get(`/movimentos-destinaveis`, filtros)
	}

	getCompensacoesFutura(filtros?) {
		return this.apiService.get(`/compensacoes-futuras`, filtros)
	}

	getCompensacoesFechamentosAnteriores(filtros?) {
		return this.apiService.get(
			`/compensacoes-fechamentos-anteriores`,
			filtros
		)
	}

	setarDestinadosFechamentoCaixa(form): Observable<any> {
		return this.apiService.post(`/fechamento-caixa-setar-destinados`, form)
	}

	getDataInicialFechamentoCaixa() {
		return this.apiService.get('/data-inicial-fechamento-caixa')
	}

	// fecharCaixa(form) {
	// 	return this.apiService.post('/fechar-caixa', form)
	// }
}
