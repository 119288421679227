import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class TratamentoRecomendacoesService {
	constructor(private apiService: ApiService) {}

	post(form): Observable<any> {
		return this.apiService.post('/recomendacoes-tratamento', form)
	}

	getAll(idTratamento): Observable<any> {
		const url = `/recomendacoes-tratamento`
		return this.apiService.get(url, idTratamento)
	}

	get(id: string): Observable<any> {
		const url = `/recomendacoes-tratamento/${id}`
		return this.apiService.get(url)
	}

	put(id: string, form): Observable<any> {
		form._method = 'PUT'
		return this.apiService.post('/recomendacoes-tratamento/' + id, form)
	}

	delete(id) {
		return this.apiService.delete('/recomendacoes-tratamento/' + id)
	}

	VerificaRecomendacaoTratamentoUpdateCliente(id) {
		return this.apiService.post(
			'/verifica-recomendacoes-tratamento-update-cliente/' + id
		)
	}
}
